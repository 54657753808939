import styled from "styled-components";
import { useHistory } from "react-router-dom";

// import { useAppTranslation } from "@hooks";
// import { Button, Text } from "@components/common";
import { device } from "@themes/default";
import { ReactComponent as VaultIcon } from "@assets/icons/vault.svg";
import { ReactComponent as LogoSimple } from "@assets/images/asx-protocol-logo.svg";
import { ReactComponent as ShareIcon } from "@assets/icons/share.svg";

const StyledVaultIcon = styled(VaultIcon)`
  height: 2.4rem;
  fill: ${({ theme }) => theme.colors.sky};
  margin-right: 0.8rem;
`;

const StyledLogoSimple = styled(LogoSimple)`
  margin-right: 0.8rem;
`;

const StyledShareIcon = styled(ShareIcon)`
  height: 2rem;
  fill: ${({ theme }) => theme.colors.white};
  margin-top: 0.2rem;
  margin-left: 0.8rem;
`;

const StyledButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.smRadius};
  margin: 0 calc(${({ theme }) => theme.card.padding} * 1);
  padding: calc(${({ theme }) => theme.card.padding} * 1.4) 0;
  transition: opacity ${({ theme }) => theme.transition.eo250};
  font-size: 1.6rem;

  @media ${device.tabletL} {
    padding: calc(${({ theme }) => theme.card.padding} * 1.2) 0;
  }

  @media ${device.tabletS} {
    padding: calc(${({ theme }) => theme.card.padding} * 1) 0;
  }

  @media ${device.mobileL} {
    padding: calc(${({ theme }) => theme.card.padding} * 0.8) 0;
    font-size: 1.5rem;
  }

  @media ${device.mobile} {
    padding: calc(${({ theme }) => theme.card.padding} * 0.6) 0;
  }

  @media ${device.mobileS} {
    padding: calc(${({ theme }) => theme.card.padding} * 0.4) 0;
    font-size: 1.4rem;
  }

  @media ${device.mobileXS} {
  }
`;

const StyledLink = styled.a`
  display: flex;
  flex-shrink: 1;
  cursor: pointer;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.smRadius};
  padding: ${({ theme }) => theme.buttonPadding};
  transition: opacity ${({ theme }) => theme.transition.eo250};
  background: ${({ theme }) => theme.colors.bgSky12};
  color: ${({ theme }) => theme.colors.sky};
  line-height: 2.4rem;
  height: max-content;

  &:first-child {
    box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.bgSky12};
    margin-top: 0;
    margin-right: ${({ theme }) => theme.mdLayoutPadding};
    margin-bottom: ${({ theme }) => theme.mdLayoutPadding};
    margin-left: ${({ theme }) => theme.mdBoxSize};
  }

  &:last-child {
    box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
    background: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.white};
    margin-left: ${({ theme }) => theme.mdBoxSize};
  }

  &:hover {
    opacity: ${({ theme }) => theme.opac64};
  }
`;

// const StyledLinkDisabled = styled(StyledLink)`
//   opacity: ${({ theme }) => theme.opac64};
//   cursor: not-allowed;
// `;

export const HomeButtons = () => {
  const history = useHistory();

  return (
    <StyledButtons>
      <StyledLink onClick={() => history.push(`/vaults`)}>
        <StyledVaultIcon />
        {/* ACTIVE VAULTS */}
        START EARNING
      </StyledLink>

      <StyledLink href={"https://mint.asx.st/"} target="_blank">
        <StyledLogoSimple /> MINT ASX
        <StyledShareIcon />
      </StyledLink>
    </StyledButtons>
  );
};
