import { FC } from "react";
import styled from "styled-components";

import { ProgressBar, Text } from "@components/common";
import { toBN, formatAmount, formatUsd, formatPercent } from "@utils";
import { useAppTranslation } from "@hooks";

type TextColor = "primary" | "contrast" | "positive" | "negative";

const CustomText = styled(Text)<{ color: TextColor }>`
  color: inherit;

  ${({ color, theme }) => {
    if (color === "contrast") {
      return `color: ${theme.colors.white}`;
    } else if (color === "primary") {
      return `color: ${theme.colors.sky}`;
    } else if (color === "positive") {
      return `color: ${theme.colors.teal}`;
    } else if (color === "negative") {
      return `color: ${theme.colors.red}`;
    }
  }}
`;

const BottomInfo = styled.div`
  display: grid;
  grid-gap: 0.2rem;
  margin-top: 2.4rem;
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 0.4rem;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    text-align: right;
  }
`;

const StyledTxBorrowLimit = styled.div`
  display: grid;
  background: ${({ theme }) => theme.colors.bgDark};
  width: 100%;
  border-radius: ${({ theme }) => theme.smRadius};
  padding: ${({ theme }) => theme.txModal.gap};
  color: ${({ theme }) => theme.colors.zinc200};
  grid-gap: 0.2rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
`;

export interface TxBorrowLimitProps {
  borrowBalance: string;
  projectedBorrowBalance?: string;
  borrowLimit: string;
  projectedBorrowLimit?: string;
  yieldType: "BORROW" | "SUPPLY";
  yieldPercent: string;
  borrowingTokens?: string;
  projectedBorrowingTokens?: string;
  tokenSymbol?: string;
}

export const TxBorrowLimit: FC<TxBorrowLimitProps> = ({
  borrowBalance,
  projectedBorrowBalance,
  borrowLimit,
  projectedBorrowLimit,
  yieldType,
  yieldPercent,
  borrowingTokens,
  projectedBorrowingTokens,
  tokenSymbol,
}) => {
  const { t } = useAppTranslation("common");

  if (!projectedBorrowBalance) projectedBorrowBalance = borrowBalance;
  if (!projectedBorrowLimit) projectedBorrowLimit = borrowLimit;

  const borrowRatio = toBN(borrowLimit).eq(0) ? "0" : toBN(borrowBalance).div(borrowLimit).toString();
  const projectedBorrowRatio = toBN(projectedBorrowLimit).eq(0)
    ? "0"
    : toBN(projectedBorrowBalance).div(projectedBorrowLimit).toString();

  const limitUsedPercent = toBN(borrowBalance).div(borrowLimit).times(100).toNumber();
  const projectedLimitUsedPercent = toBN(projectedBorrowBalance).div(projectedBorrowLimit).times(100).toNumber();
  let diffType: "positive" | "negative" | undefined;
  if (projectedLimitUsedPercent && limitUsedPercent !== projectedLimitUsedPercent) {
    diffType = limitUsedPercent > projectedLimitUsedPercent ? "positive" : "negative";
  }

  return (
    <StyledTxBorrowLimit>
      <Info>
        <Text>{t("components.transaction.borrow-limit.total-borrow-limit")}</Text>
        <Text>
          <Text>{formatUsd(borrowLimit)}</Text>
          {borrowLimit !== projectedBorrowLimit && <>{` → ${formatUsd(projectedBorrowLimit)}`}</>}
        </Text>
      </Info>

      <Info>
        <Text>{t("components.transaction.borrow-limit.total-borrow-limit-used")}</Text>
        <Text>
          <CustomText color="primary">{formatPercent(borrowRatio, 0)}&nbsp;</CustomText>
          <CustomText color={diffType ?? "primary"}>
            {formatPercent(borrowRatio, 0) !== formatPercent(projectedBorrowRatio, 0) && (
              <>{` → ${formatPercent(projectedBorrowRatio, 0)}`}</>
            )}
          </CustomText>
        </Text>
      </Info>

      <StyledProgressBar value={limitUsedPercent} diffValue={projectedLimitUsedPercent} />

      <BottomInfo>
        {borrowingTokens && (
          <Info>
            <Text>{t("components.transaction.borrow-limit.borrowing")}</Text>
            <Text>
              <CustomText color={diffType ?? "primary"}>
                {formatAmount(projectedBorrowingTokens ?? borrowingTokens, 4)}
              </CustomText>
              <CustomText color="contrast">&nbsp;{tokenSymbol}</CustomText>
            </Text>
          </Info>
        )}

        <Info>
          <Text>
            {yieldType === "BORROW"
              ? t("components.transaction.borrow-limit.borrow-apy")
              : t("components.transaction.borrow-limit.supply-apy")}
          </Text>
          <CustomText color="contrast">{yieldPercent}</CustomText>
        </Info>
      </BottomInfo>
    </StyledTxBorrowLimit>
  );
};
