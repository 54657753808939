import { FC } from "react";
import styled from "styled-components";

import { ReactComponent as LogoSimple } from "@assets/images/asx-protocol-logo.svg";
// import { ReactComponent as LogoFull } from "@assets/images/asx-protocol-logo-full.svg";
import { ReactComponent as LogoWhite } from "@assets/images/asx-protocol-logo-white.svg";

export interface LogoProps {
  className?: string;
  full?: boolean;
  onClick?: () => void;
}

const StyledLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  height: 2.4rem;
  fill: ${({ theme, color }) => color ?? theme.colors.logo};
  transition: opacity ${({ theme }) => theme.transition.eo250};

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLogoSimple = styled(LogoSimple)`
  height: 100%;
  width: auto;
  fill: inherit;
`;

// const StyledLogoFull = styled(LogoFull)`
//   height: 100%;
//   width: auto;
//   fill: inherit;
// `;

const StyledLogoWhite = styled(LogoWhite)`
  height: 100%;
  width: auto;
  fill: inherit;
`;

export const Logo: FC<LogoProps> = ({ className, full, onClick, ...props }) => {
  const logoSvg = full ? <StyledLogoWhite /> : <StyledLogoSimple />;

  return (
    <StyledLogo className={className} onClick={onClick} {...props}>
      {logoSvg}
    </StyledLogo>
  );
};
