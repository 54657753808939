import styled from "styled-components";

import { Img } from "@components/common";

const fallbackIcon = "";

const StyledTokenIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type TokenIconSize = "default" | "big" | "xBig" | "xxBig";

interface TokenIconProps {
  icon?: string;
  symbol?: string;
  size?: TokenIconSize;
}

export const TokenIcon = ({ icon, symbol, size, ...props }: TokenIconProps) => {
  const src = icon === "" || !icon ? fallbackIcon : icon;
  // TODO: use rem units instead of pixels
  let height;
  switch (size) {
    case "big":
      // height = "4.2rem";
      height = 42;
      break;
    case "xBig":
      // height = "4.8rem";
      height = 48;
      break;
    case "xxBig":
      // height = "5.5rem";
      height = 55;
      break;
    default:
      // height = "3.2rem";
      height = 32;
      break;
  }
  const width = height;
  const style = {
    minWidth: width,
    minHeight: height,
    width: width,
    height: height,
  };

  return (
    <StyledTokenIcon {...props}>
      {src && <Img alt={symbol ?? "n/a"} width={width} height={height} src={src} />}
    </StyledTokenIcon>
  );
};
