import styled from "styled-components";

import { useAppSelector, useAppTranslation } from "@hooks";
import { LabsSelectors, TokensSelectors, VaultsSelectors, WalletSelectors, NetworkSelectors } from "@store";
// import { SummaryCard, InfoCard, HomeLayout, ViewContainer, NoWalletCard, Amount, HomeButtons } from "@components/app";
import { HomeLayout, ViewContainer, Amount, HomeButtons } from "@components/app";
// import { Text } from "@components/common";
import { device } from "@themes/default";
// import { toBN, halfWidthCss } from "@utils";
import { toBN } from "@utils";
import { getConfig } from "@config";
import { ReactComponent as Chart } from "@assets/images/chart.svg";

const StyledViewContainer = styled(ViewContainer)`
  /* position: relative; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
`;

// const HeaderCard = styled(SummaryCard)`
//   /* display: none; */
//   grid-column: 1 / 3;
// `;

const Row = styled.div`
  // display: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-column: 1 / 3;
  grid-gap: ${({ theme }) => theme.lgLayoutPadding};
`;

// const StyledInfoCard = styled(InfoCard)`
//   // display: none;
//   max-width: 100%;
//   flex: 1;
// `;

// const StyledNoWalletCard = styled(NoWalletCard)`
//   // display: none;
//   grid-column: 1 / 3;
//   ${halfWidthCss}
// `;

// const StyledSummaryCard = styled(SummaryCard)`
//   /* display: none; */
//   width: 100%;
//   grid-column: 1 / 3;
//   ${halfWidthCss};
// `;

// const StyledLink = styled.a`
//   white-space: initial;
//   text-decoration: underline;
//   color: inherit;
// `;

const StyledChartWrapper = styled.div`
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(15%, -35%);
  display: flex;
  height: 100%;
  width: 100%;
  opacity: ${({ theme }) => theme.opac24};
  z-index: -1;

  @media screen and (max-height: 599px) {
    display: none;
  }

  @media ${device.desktopS} {
    transform: translate(15%, -30%);
  }

  @media ${device.tabletL} {
    display: none;
  }
`;

const StyledChart = styled(Chart)``;

const StyledHomeLayout = styled(HomeLayout)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;

  @media ${device.tabletS} {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin: 7.2rem auto 3.6rem auto;
  }

  @media ${device.mobileL} {
    margin: 4rem auto 2rem auto;
  }

  @media ${device.mobileS} {
    margin: 3.2rem auto 1.6rem auto;
  }
`;

export const Home = () => {
  // TODO: Add translation
  const { t } = useAppTranslation(["common", "home"]);
  const { NETWORK_SETTINGS } = getConfig();
  const currentNetwork = useAppSelector(NetworkSelectors.selectCurrentNetwork);
  const currentNetworkSettings = NETWORK_SETTINGS[currentNetwork];
  const walletIsConnected = useAppSelector(WalletSelectors.selectWalletIsConnected);
  const vaultsSummary = useAppSelector(VaultsSelectors.selectSummaryData);
  const labsSummary = useAppSelector(LabsSelectors.selectSummaryData);
  const walletSummary = useAppSelector(TokensSelectors.selectSummaryData);

  const netWorth = toBN(vaultsSummary.totalDeposits)
    .plus(walletSummary.totalBalance)
    .plus(labsSummary.totalDeposits)
    .toString();

  const summaryCardItems = [
    { header: t("dashboard.total-net-worth"), Component: <Amount value={netWorth} input="usdc" /> },
  ];
  if (currentNetworkSettings.earningsEnabled) {
    summaryCardItems.push(
      {
        header: t("dashboard.vaults-earnings"),
        Component: <Amount value={vaultsSummary.totalEarnings} input="usdc" />,
      },
      {
        header: t("dashboard.vaults-est-yearly-yield"),
        Component: <Amount value={vaultsSummary.estYearlyYeild} input="usdc" />,
      }
    );
  }

  return (
    <StyledViewContainer>
      <div id="background-radial-gradient"></div>

      {/* <HeaderCard header={t('dashboard.header')} items={summaryCardItems} variant="secondary" cardSize="small" /> */}

      <Row>
        <StyledHomeLayout
          header={"YIELD STRATEGIES."}
          subheader={"UNIFIED."}
          content="A PROTOCOL FOR AGGREGATED STRATEGIES ON STAKED ASSETS."
          Component={<HomeButtons />}
          cardSize="big"
        />

        {/* <StyledInfoCard
          header={t('components.beta-card.header')}
          Component={
            <Text>
              <p>
                {t('components.beta-card.desc-1')} <StyledLink href="/">Discord</StyledLink>.
              </p>
              <p>
                {t('components.beta-card.desc-2')} <StyledLink href="/">ASX Protocol</StyledLink>.
              </p>
            </Text>
          }
          cardSize="big"
        /> */}
      </Row>

      {walletIsConnected && (
        <>
          <Row>
            {/* <StyledSummaryCard
              header={t('')}
              items={[
                {
                  header: t('dashboard.available-deposit'),
                  Component: <Amount value={walletSummary.totalBalance} input="usdc" />,
                },
              ]}
              redirectTo="wallet"
              cardSize="small"
            /> */}

            {/* <StyledSummaryCard
              header={t('navigation.vaults')}
              items={[
                {
                  header: t('dashboard.holdings'),
                  Component: <Amount value={vaultsSummary.totalDeposits} input="usdc" />,
                },
                {
                  header: t('dashboard.apy'),
                  Component: <Amount value={vaultsSummary.apy} input="percent" />,
                },
              ]}
              redirectTo="vaults"
              cardSize="small"
            /> */}
          </Row>

          {/* {currentNetworkSettings.labsEnabled && (
            <StyledSummaryCard
              header={t('')}
              items={[
                {
                  header: t('dashboard.holdings'),
                  Component: <Amount value={labsSummary.totalDeposits} input="usdc" />,
                },
              ]}
              redirectTo="labs"
              cardSize="small"
            />
          )} */}
        </>
      )}

      {/* {!walletIsConnected && <StyledNoWalletCard />} */}

      <StyledChartWrapper>
        <StyledChart />
      </StyledChartWrapper>
    </StyledViewContainer>
  );
};
