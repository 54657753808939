import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";

import { useAppTranslation, useAppSelector } from "@hooks";
import { SettingsSelectors } from "@store";
import { NavigationLink } from "@components/app";
import { Link, Icon, Logo, RedirectIcon } from "@components/common";

// const linkHoverFilter = "brightness(90%)";
// const linkTransition = "filter 200ms ease-in-out";

const SidebarHeader = styled.div`
  display: grid;
  // display: none;
  gap: 1.2rem;
`;

const SidebarContent = styled.nav`
  display: flex;
  // display: none;
  flex-direction: column;
  padding-top: 2rem;
  flex: 1;
`;

// const SidebarFooter = styled.div``;

const StyledLogo = styled(Logo)`
  justify-content: flex-start;
  height: 2.4rem;
  fill: ${({ theme }) => theme.colors.logo};
  cursor: pointer;
`;

const LinkList = styled.div`
  display: grid;
  gap: 1.2rem;
  margin-top: 2rem;
`;

const LinkRedirectIcon = styled(Icon)`
  display: inline-block;
  fill: currentColor;
  width: 1.1rem;
`;

const LinkIcon = styled(Icon)`
  margin-right: 1.2rem;
  fill: ${({ theme }) => theme.colors.zinc200};
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
`;

const LinkText = styled.span`
  white-space: nowrap;
`;

const RouterLink = styled(Link)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  color: inherit;
  font-size: 1.6rem;
  /* font-weight: 400; */

  &:hover span {
    filter: brightness(90%);
  }

  span {
    transition: filter ${({ theme }) => theme.transition.eo250};
  }
  ${(props) =>
    props.selected &&
    `
    ${LinkIcon} {
      fill: ${props.theme.colors.zinc200};
    }
    ${LinkText} {
      color: ${props.theme.colors.zinc200};
    }
  `}
`;

// const ToggleSidebarButton = styled(LinkIcon)`
//   fill: ${({ theme }) => theme.colors.zinc200};
//   transition: filter ${({ theme }) => theme.transition.eo250};
//   &:hover {
//     filter: brightness(90%);
//   }
//   margin-right: 0;
// `;

const StyledSidebar = styled.div<{ collapsed?: boolean }>`
  display: flex;
  // display: none;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.zinc200};
  background: ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ theme }) => theme.smRadius};
  width: ${({ theme }) => theme.sideBar.width};
  height: 100%;
  max-width: 100%;
  max-height: calc(100% - ${({ theme }) => theme.card.padding} * 2);
  top: ${({ theme }) => theme.card.padding};
  padding: 1rem 1.2rem;
  position: fixed;
  top: ${({ theme }) => theme.lgLayoutPadding};
  max-height: 3.2rem;
  transition: width ${({ theme }) => theme.transition.eo250};
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: ${({ theme }) => theme.zindex.navSidemenu};

  ${(props) =>
    props.collapsed &&
    `
    width: ${props.theme.sideBar.collapsedWidth};

    ${LinkText} {
      display: none;
    }
  `};
`;
interface NavSidebarProps {
  navLinks: NavigationLink[];
}

export const NavSidebar = ({ navLinks, ...props }: NavSidebarProps) => {
  const { t } = useAppTranslation("common");
  const location = useLocation();
  const history = useHistory();
  const collapsedSidebar = useAppSelector(SettingsSelectors.selectSidebarCollapsed);

  const currentPath = "/" + location.pathname.toLowerCase().split("/")[1];

  // const toggleSidebar = () => {
  //   if (isMobile && collapsedSidebar) {
  //     return;
  //   }

  //   dispatch(SettingsActions.toggleSidebar());
  // };

  const linkList = (
    <LinkList className="link-list">
      {navLinks.map((link, index) => {
        return (
          <RouterLink to={link.to} key={index} selected={currentPath === link.to} external={link.external}>
            <LinkIcon Component={link.icon} />
            <LinkText>
              {t(link.text)} {link.external && <LinkRedirectIcon Component={RedirectIcon} />}
            </LinkText>
          </RouterLink>
        );
      })}
    </LinkList>
  );

  return (
    <StyledSidebar collapsed={collapsedSidebar}>
      <SidebarHeader>
        <StyledLogo full={!collapsedSidebar} onClick={() => history.push("/")} />
        {/* {!isMobile && <ToggleSidebarButton Component={CollapseIcon} onClick={toggleSidebar} />} */}
      </SidebarHeader>

      <SidebarContent>{linkList}</SidebarContent>
      {/* <SidebarFooter>
      </SidebarFooter> */}
    </StyledSidebar>
  );
};
