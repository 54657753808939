import styled from "styled-components";

import { Card, CardHeader, CardContent, Text, Icon, ChevronRightIcon } from "@components/common";
import { TokenIcon } from "@components/app";

const TokenListIconSize = "1rem";

const ContainerCard = styled(Card)`
  padding: ${({ theme }) => theme.card.padding} 0;
  width: 100%;
  min-width: 56%;
  border-radius: 0.2rem;
`;

const StyledCardContent = styled(CardContent)`
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.card.padding};
  margin-top: ${({ theme }) => theme.card.padding};
  padding: 0 ${({ theme }) => theme.card.padding};
`;

const ItemCard = styled(Card)<{ onClick: any }>`
  display: flex;
  align-items: center;
  max-width: 18rem;
  min-width: 16rem;
  flex: 1;
  padding: 0.8rem;
  background: ${({ theme }) => theme.colors.darkGray};
  transition: filter ${({ theme }) => theme.transition.eo250};

  ${({ onClick, theme }) =>
    onClick &&
    `
    cursor: pointer;
    &:hover {
      filter: brightness(90%);
    }
  `};
`;

const ItemHeader = styled(Text)`
  position: absolute;
  font-size: 1.4rem;
`;

const ItemInfo = styled(Text)`
  color: ${({ theme }) => theme.colors.zinc200};
  font-size: 1.6rem;
  padding-bottom: 0.4rem;
  margin-top: 2rem;
  position: relative;
  max-width: 100%;
  overflow: hidden;
`;

const ItemInfoLabel = styled(Text)`
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: -0.4rem;
  transform: translateX(100%);
`;

const ItemName = styled(Text)`
  color: ${({ theme }) => theme.colors.zinc200};
  font-size: 1.4rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0.8rem;
  text-align: center;
  flex: 1;
`;

const TokenListIcon = styled(Icon)`
  position: absolute;
  right: 3rem;
  fill: currentColor;
  width: ${TokenListIconSize};
  transition: color 200ms ease-in-out;
`;

const CenterIcon = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.lgLayoutPadding};
  user-select: none;
`;

interface Item {
  header?: string;
  icon: string;
  name: string;
  info: string;
  infoDetail?: string;
  action?: string;
  onAction?: () => void;
}

interface RecommendationsProps {
  header?: string;
  subHeader?: string;
  items: Item[];
}

export const RecommendationsCard = ({ header, subHeader, items, ...props }: RecommendationsProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <ContainerCard {...props}>
      <CardHeader header={header} subHeader={subHeader} />

      <StyledCardContent>
        {items.map((item, i) => (
          <ItemCard key={`${i}-${item.name}`} variant="primary" onClick={item.onAction ? item.onAction : undefined}>
            {item.header && <ItemHeader>{item.header}</ItemHeader>}

            <CenterIcon>
              <TokenIcon symbol={item.name} icon={item.icon} size="xBig" />
            </CenterIcon>

            <ItemInfo>
              <ItemName>{item.name}</ItemName>
              <ItemInfoLabel>{item.info}</ItemInfoLabel>
            </ItemInfo>

            {item.onAction && <TokenListIcon Component={ChevronRightIcon} />}
          </ItemCard>
        ))}
      </StyledCardContent>
    </ContainerCard>
  );
};
