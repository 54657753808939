import { ElementType, FC, useState } from "react";
import styled from "styled-components";

// import { ChevronDownIcon, Icon } from "./Icon";
import { Icon } from "./Icon";
import { Text } from "./Text";
import { SpinnerLoading } from "./SpinnerLoading";

const StyledOptionList = styled.div<{ disabled?: boolean; tabIndex: number; selectable?: boolean }>`
  --dropdown-selected-color: ${({ theme }) => theme.colors.zinc200};
  --dropdown-selected-background: ${({ theme }) => theme.colors.lightGray};

  display: flex;
  background: ${({ theme }) => theme.colors.darkGray};
  color: transparent;
  outline: none;
  border: none;
  fill: currentColor;
  stroke: currentColor;
  user-select: none;
  border-radius: ${({ theme }) => theme.xxlRadius};
  position: relative;
  font-size: 1.6rem;
  cursor: ${({ selectable }) => (selectable ? "pointer" : null)};
  max-width: max-content;

  ${(props) =>
    props.disabled &&
    `
    cursor: default;
    pointer-events: none;
  `}
`;

const StyledText = styled(Text)`
  flex: 1;
`;

const StyledSpinnerLoading = styled(SpinnerLoading)`
  flex: 1;
  font-size: 1.6rem;
`;

// const ArrowIcon = styled(Icon)<{ open?: boolean }>`
//   margin-left: 0.8rem;
//   height: 1rem;
//   transition: transform ${({ theme }) => theme.transition.eo250};
//   transform: rotate(${({ open }) => (open ? "180deg" : "0deg")});
// `;

const StyledIcon = styled(Icon)`
  height: 3.8rem;
  width: 3.8rem;
  flex-shrink: 1;
  color: transparent;
`;

const Options = styled.div<{ open?: boolean; listPosition: "top" | "bottom" }>`
  display: none;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  background: ${({ theme }) => theme.colors.darkGray};
  width: 100%;
  max-height: 20rem;
  left: 0;
  border-radius: ${({ theme }) => theme.smRadius};
  overflow: hidden;
  overflow-y: auto;
  z-index: 1;

  ${(props) => props.open && `display: flex;`}
  ${(props) =>
    props.listPosition === "top" &&
    `
    top: -0.8rem;
    transform: translateY(-100%);
  `}
  ${(props) =>
    props.listPosition === "bottom" &&
    `
      bottom: -0.8rem;
      transform: translateY(100%);
  `}
`;

const OptionChild = styled.div<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: ${({ theme }) => theme.smRadius};
  padding: 0.3rem;
  width: 100%;
  position: relative;
  flex-shrink: 1;
  transition: opacity ${({ theme }) => theme.transition.eo250};

  :hover {
    opacity: ${({ theme }) => theme.opac64};
  }

  ${(props) =>
    props.selected &&
    `
      color: var(--dropdown-selected-color);
      background: var(--dropdown-selected-background);
  `}
`;

interface Option {
  value: string;
  label: string;
  Icon?: ElementType;
}

export interface OptionListProps {
  selected: Option;
  setSelected: (selected: Option) => void;
  options: Option[];
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  hideIcons?: boolean;
  listPosition?: "top" | "bottom" | undefined;
  onChange?: (selected: Option) => void;
}

export const OptionList: FC<OptionListProps> = ({
  selected,
  setSelected,
  options,
  className,
  isLoading,
  disabled,
  hideIcons,
  onChange,
  listPosition,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const isSingleOption = options.length === 1;

  let selectedText = "None";
  if (selected?.value) {
    selectedText = selected.label;
  }

  const selectOption = (option: Option) => {
    if (disabled) {
      return;
    }

    setSelected(option);
    setOpen(false);

    if (onChange) {
      onChange(option);
    }
  };

  return (
    <StyledOptionList
      className={className}
      tabIndex={0}
      disabled={disabled}
      selectable={!isSingleOption}
      onBlur={() => setOpen(false)}
      {...props}
    >
      <OptionChild onClick={() => (!isSingleOption && !disabled ? setOpen(!open) : null)}>
        {!hideIcons && selected?.Icon && <StyledIcon Component={selected.Icon} />}

        {!isLoading && <StyledText ellipsis>{selectedText}</StyledText>}
        {isLoading && <StyledSpinnerLoading />}

        {/* <ArrowIcon Component={ChevronDownIcon} open={open} /> */}
      </OptionChild>

      <Options open={open} listPosition={listPosition ? listPosition : "bottom"}>
        {options.map((option) => (
          <OptionChild
            key={option.value}
            onClick={() => selectOption(option)}
            selected={option.value === selected.value}
          >
            {!hideIcons && option?.Icon && <StyledIcon Component={option.Icon} />}
            <StyledText ellipsis>{option.label}</StyledText>
          </OptionChild>
        ))}
      </Options>
    </StyledOptionList>
  );
};
