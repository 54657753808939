import { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

// NOTE: App errors out when trying to import directly from 'common'
import { ChevronRightIcon, Icon } from "@components/common/Icon";

const Arrow = styled(Icon)`
  height: 1.6rem;
  fill: currentColor;
`;

const StyledCardRedirection = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 3.6rem;
  transition: color ${({ theme }) => theme.transition.eo250};

  &:hover {
    color: ${({ theme }) => theme.colors.zinc200};
  }
`;

interface CardRedirectionProps {
  redirectTo: string;
  className?: string;
}

export const CardRedirection: FC<CardRedirectionProps> = ({ children, redirectTo, className, ...props }) => {
  const history = useHistory();

  return (
    <StyledCardRedirection className={className} {...props} onClick={() => history.push(`/${redirectTo}`)}>
      <Arrow Component={ChevronRightIcon} />
    </StyledCardRedirection>
  );
};
