import { FC } from "react";
import styled from "styled-components";

import { device } from "@themes/default";

import { Modal } from "./Modal";

const StyledModalTx = styled(Modal)`
  min-width: 48rem;
  width: ${({ theme }) => theme.txModal.width};
  padding: ${({ theme }) => theme.xsLayoutPadding} 0;
  height: initial;
  background: transparent;

  @media ${device.mobileL} {
    min-width: 98vw;
  }
`;

export interface StyledModalTxProps {}

export const ModalTx: FC<StyledModalTxProps> = ({ children, ...props }) => {
  return <StyledModalTx {...props}>{children}</StyledModalTx>;
};
