import { FC } from "react";
import styled from "styled-components";

export interface ToggleButtonProps {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  className?: string;
  disabled?: boolean;
  color?: string;
  onClick?: () => void;
  ariaLabel?: string;
}

const ToggleCircle = styled.div`
  background: var(--toggle-color);
  border-radius: 100%;
  width: calc(var(--toggle-size) - 0.8rem);
  height: calc(var(--toggle-size) - 0.8rem);
  position: absolute;
  transition: transform ${({ theme }) => theme.transition.eo250};
  left: 50%;
  transform: translateX(calc(-100% - var(--toggle-offset) / 2 - var(--toggle-x-offset)));
`;

const StyledToggleButton = styled.button<{ selected?: boolean }>`
  --toggle-size: 2.6rem;
  --toggle-offset: 0.2rem;
  --toggle-color: ${({ theme }) => theme.colors.zinc200};
  --toggle-background: ${({ theme }) => theme.colors.darkGray};

  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--toggle-background);
  border-radius: ${({ theme }) => theme.smRadius};
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  height: var(--toggle-size);
  width: calc(var(--toggle-size) * 2 + var(--toggle-offset));
  transition: all ${({ theme }) => theme.transition.eo250};
  position: relative;

  ${({ selected, theme }) =>
    selected &&
    `
    --toggle-color: ${theme.colors.white};      
    --toggle-background: ${theme.colors.lightGray};
    
    ${ToggleCircle} {
        transform: translateX(calc(0% + var(--toggle-offset) / 2));
      }
  `};

  &:hover {
    filter: brightness(90%);
  }
`;

export const ToggleButton: FC<ToggleButtonProps> = ({
  selected,
  setSelected,
  className,
  disabled,
  color,
  onClick,
  ariaLabel,
  ...props
}) => (
  <StyledToggleButton
    className={className}
    selected={selected}
    disabled={disabled}
    color={color}
    aria-label={ariaLabel}
    onClick={() => setSelected(!selected)}
    {...props}
  >
    <ToggleCircle />
  </StyledToggleButton>
);
