import { DefaultTheme } from "styled-components";

import { sharedTheme } from "../default";

const darkTheme: DefaultTheme = {
  ...sharedTheme,
  colors: {
    logo: "#DE2F52",

    white: "rgb(255, 255, 255)",
    white16: "rgba(255, 255, 255, 0.16)",
    white32: "rgba(255, 255, 255, 0.32)",
    white48: "rgba(255, 255, 255, 0.48)",
    white64: "rgba(255, 255, 255, 0.64)",
    white80: "rgba(255, 255, 255, 0.80)",
    white96: "rgba(255, 255, 255, 0.96)",

    black: "rgb(0, 0, 0)",
    black16: "rgba(0, 0, 0, 0.16)",
    black32: "rgba(0, 0, 0, 0.32)",
    black48: "rgba(0, 0, 0, 0.48)",
    black64: "rgba(0, 0, 0, 0.64)",
    black80: "rgba(0, 0, 0, 0.80)",
    black96: "rgba(0, 0, 0, 0.96)",

    zinc200: "rgb(228, 228, 231)",
    zinc300: "rgb(212, 212, 216)",
    zinc400: "rgb(161, 161, 170)",
    zinc500: "rgb(113, 113, 122)",
    zinc600: "rgb(82, 82, 91)",

    bgDark: "rgb(3, 3, 9)",
    bgDark16: "rgba(3, 3, 9, 0.16)",
    bgDark32: "rgba(3, 3, 9, 0.32)",
    bgDark64: "rgba(3, 3, 9, 0.64)",

    bgGradGray:
      "linear-gradient(270deg, rgba(172, 172, 171, 0.082) 1.04%, rgba(172, 172, 171, 0.27) 98.99%) 0% 0% / 200% 200%",

    darkGray16: "rgba(18, 18, 24, 0.16)",
    darkGray32: "rgba(18, 18, 24, 0.32)",
    darkGray48: "rgba(18, 18, 24, 0.48)",
    darkGray64: "rgba(18, 18, 24, 0.64)",
    darkGray: "rgb(18, 18, 24)",

    lightGray: "rgb(28, 28, 34)",
    lightGray08: "rgba(28, 28, 34, 0.08)",
    lightGray16: "rgba(28, 28, 34, 0.16)",
    lightGray32: "rgba(28, 28, 34, 0.32)",
    lightGray64: "rgba(28, 28, 34, 0.64)",

    textGray: "rgb(172, 172, 171)",
    textGray16: "rgba(172, 172, 171, 0.16)",
    textGray32: "rgba(172, 172, 171, 0.32)",
    textGray64: "rgba(172, 172, 171, 0.64)",

    bgWallet: "rgba(22, 206, 185, 0.08)",
    textWallet: "rgb(22, 206, 185)",

    textGreenDark: "rgb(11, 84, 79)",

    red: "rgb(232, 65, 66)",
    red40: "rgb(232 65 66 / 40%)",
    borderRed: "solid 0.2rem rgb(232, 65, 66)",
    shadowRed: "rgb(232 65 66 / 40%) 0 0 4rem",
    bgRed: "rgba(232, 65, 66, 0.16)",
    bgRedGrad:
      "linear-gradient(270deg, rgba(232, 65, 66, 0.082) 1.04%, rgba(232, 65, 66, 0.27) 98.99%) 0% 0% / 200% 200%",

    gold: "rgb(227, 150, 82)",
    gold40: "rgb(227 150 82 / 40%)",
    borderGold: "solid 0.2rem rgb(227, 150, 82)",
    shadowGold: "rgb(227 150 82 / 40%) 0 0 4rem",
    bgGold: "rgba(227, 150, 82, 0.16)",
    bgGoldGrad:
      "linear-gradient(270deg, rgba(227, 150, 82, 0.082) 1.04%, rgba(227, 150, 82, 0.27) 98.99%) 0% 0% / 200% 200%",

    indigo: "rgb(98, 126, 234)",
    indigo40: "rgb(98 126 234 / 40%)",
    borderIndigo: "solid 0.2rem rgb(98, 126, 234)",
    shadowIndigo: "rgb(98 126 234 / 40%) 0 0 4rem",
    bgIndigo: "rgba(98, 126, 234, 0.16)",
    bgIndigoGrad:
      "linear-gradient(270deg, rgba(98, 126, 234, 0.082) 1.04%, rgba(98, 126, 234, 0.27) 98.99%) 0% 0% / 200% 200%",

    blue: "rgb(62, 115, 196)",
    blue40: "rgb(62 115 196 / 40%)",
    borderBlue: "solid 0.2rem rgb(62, 115, 196)",
    shadowBlue: "rgb(62 115 196 / 40%) 0 0 4rem",
    bgBlue: "rgba(62, 115, 196, 0.16)",
    bgBlueGrad:
      "linear-gradient(270deg, rgba(62, 115, 196, 0.082) 1.04%, rgba(62, 115, 196, 0.27) 98.99%) 0% 0% / 200% 200%",

    sky: "rgb(0, 163, 255)",
    sky10: "rgb(0 163 255 / 10%)",
    sky20: "rgb(0 163 255 / 20%)",
    sky40: "rgb(0 163 255 / 40%)",
    sky60: "rgb(0 163 255 / 60%)",
    sky80: "rgb(0 163 255 / 80%)",
    borderSky: "solid 0.2rem rgb(0, 163, 255)",
    shadowSky: "rgb(0 163 255 / 40%) 0 0 4rem",
    bgSky04: "rgba(0, 163, 255, 0.04)",
    bgSky08: "rgba(0, 163, 255, 0.08)",
    bgSky12: "rgba(0, 163, 255, 0.12)",
    bgSky16: "rgba(0, 163, 255, 0.16)",
    bgSky32: "rgba(0, 163, 255, 0.32)",
    bgGradSky:
      "linear-gradient(270deg, rgba(0, 163, 255, 0.082) 1.04%, rgba(0, 163, 255, 0.27) 98.99%) 0% 0% / 200% 200%",

    cyan: "rgb(17, 186, 240)",
    cyan10: "rgb(17 186 240 / 10%)",
    cyan20: "rgb(17 186 240 / 20%)",
    cyan40: "rgb(17 186 240 / 40%)",
    cyan60: "rgb(17 186 240 / 60%)",
    cyan80: "rgb(17 186 240 / 80%)",
    borderCyan: "solid 0.2rem rgb(17, 186, 240)",
    shadowCyan: "rgb(17 186 240 / 40%) 0 0 4rem",
    bgCyan: "rgba(17, 186, 240, 0.16)",
    bgCyanGrad:
      "linear-gradient(270deg, rgba(17, 186, 240, 0.082) 1.04%, rgba(17, 186, 240, 0.27) 98.99%) 0% 0% / 200% 200%",

    teal: "rgb(46, 186, 198)",
    teal40: "rgb(46 186 198 / 40%)",
    borderTeal: "solid 0.2rem rgb(46, 186, 198)",
    shadowTeal: "rgb(46 186 198 / 40%) 0 0 4rem",
    bgTeal: "rgba(46, 186, 198, 0.16)",
    bgTealGrad:
      "linear-gradient(270deg, rgba(46, 186, 198, 0.082) 1.04%, rgba(46, 186, 198, 0.27) 98.99%) 0% 0% / 200% 200%",

    green: "rgb(20, 241, 149)",
    green40: "rgb(20 241 149 / 40%)",
    borderGreen: "solid 0.2rem rgb(20, 241, 149)",
    shadowGreen: "rgb(20 241 149 / 40%) 0 0 4rem",
    bgGreen: "rgba(20, 241, 149, 0.16)",
    bgGreenGrad:
      "linear-gradient(270deg, rgba(20, 241, 149, 0.082) 1.04%, rgba(20, 241, 149, 0.27) 98.99%) 0% 0% / 200% 200%",

    // bgSlate: '#0f172a4d',
    // textSlate: '#94a3b8',
    // bgHoverSlate: '#0f172a33',
    // textHoverSlate: '#94a3b899',
    // // textHoverSlate: '#475569',

    // bgRed: '#7f1d1d4d',
    // textRed: '#f87171',
    // bgHoverRed: '#7f1d1d33',
    // textHoverRed: '#f8717199',
    // // textHoverRed: '#dc2633',

    // bgAmber: '#78350f4d',
    // textAmber: '#fbbf24',
    // bgHoverAmber: '#78350f33',
    // textHoverAmber: '#fbbf2499',
    // // textHoverAmber: '#d97706',

    // bgOrange: '#7c2d124d',
    // textOrange: '#fb923c',
    // bgHoverOrange: '#7c2d1233',
    // textHoverOrange: '#fb923c99',
    // // textHoverOrange: '#ea580c',

    // bgEmerald: '#064e3b4d',
    // textEmerald: '#34d399',
    // bgHoverEmerald: '#064e3b33',
    // textHoverEmerald: '#34d39999',
    // // textHoverEmerald: '#059669',

    // bgTeal: '#134e4a33',
    // // bgTeal: '#134e4a4d',
    // textTeal: '#2dd4bf',
    // bgHoverTeal: '#134e4a26',
    // // bgHoverTeal: '#134e4a33',
    // textHoverTeal: '#2dd4bf99',
    // // textHoverTeal: '#0d9488',

    // bgCyan: '#164e634d',
    // textCyan: '#22d3ee',
    // bgHoverCyan: '#164e6333',
    // textHoverCyan: '#22d3ee99',
    // // textHoverCyan: '#0891b2',

    // bgSky: '#0c4a6e4d',
    // textSky: '#38bdf8',
    // bgHoverSky: '#0c4a6e33',
    // textHoverSky: '#38bdf899',
    // // textHoverSky: '#0284c7',

    // bgBlue: '#1e3a8a4d',
    // textBlue: '#60a5fa',
    // bgHoverBlue: '#1e3a8a33',
    // textHoverBlue: '#60a5fa99',
    // // textHoverBlue: '#2563eb',

    // bgIndigo: '#312e814d',
    // textIndigo: '#818cf8',
    // bgHoverIndigo: '#312e8133',
    // textHoverIndigo: '#818cf899',
    // // textHoverIndigo: '#2563eb',

    // bgPink: '#8318434d',
    // textPink: '#f472b6',
    // bgHoverPink: '#83184333',
    // textHoverPink: '#f472b699',
    // // textHoverPink: '#db2777',

    // bgRose: '#8813374d',
    // textRose: '#fb7185',
    // bgHoverRose: '#88133733',
    // textHoverRose: '#fb718599',
    // // textHoverRose: '#e11d48',
  },
};

export { darkTheme };
