import { memoize } from "lodash";

import { Env, Network } from "@types";

export const getEnv = memoize((): Env => {
  const {
    NODE_ENV,
    REACT_APP_VERSION,
    REACT_APP_NETWORK,
    REACT_APP_CUSTOM_PROVIDER_HTTPS,
    REACT_APP_USE_MAINNET_FORK,
    REACT_APP_USE_SDK_MOCK,
    REACT_APP_ALLOW_DEV_MODE,
    REACT_APP_INFURA_PROJECT_ID,
    REACT_APP_ETHERSCAN_API_KEY,
    REACT_APP_ALCHEMY_API_KEY,
    REACT_APP_BLOCKNATIVE_KEY,
    REACT_APP_FORTMATIC_KEY,
    REACT_APP_PORTIS_KEY,
    REACT_APP_ZAPPER_API_KEY,
    REACT_APP_YEARN_SUBGRAPH_KEY,
  } = process.env;

  return {
    // ENV: NODE_ENV,
    ENV: "production",
    VERSION: REACT_APP_VERSION ?? "unknown",
    NETWORK: (REACT_APP_NETWORK ?? "mainnet") as Network,
    CUSTOM_PROVIDER_HTTPS: REACT_APP_CUSTOM_PROVIDER_HTTPS ?? "http://127.0.0.1:8545/",
    USE_MAINNET_FORK: REACT_APP_USE_MAINNET_FORK === "true",
    USE_SDK_MOCK: REACT_APP_USE_SDK_MOCK === "true",
    ALLOW_DEV_MODE: REACT_APP_ALLOW_DEV_MODE === "true",
    // INFURA_PROJECT_ID: REACT_APP_INFURA_PROJECT_ID,
    INFURA_PROJECT_ID: "",
    // ETHERSCAN_API_KEY: REACT_APP_ETHERSCAN_API_KEY,
    ETHERSCAN_API_KEY: "GEQXZDY67RZ4QHNU1A57QVPNDV3RP1RYH4",
    // ALCHEMY_API_KEY: REACT_APP_ALCHEMY_API_KEY,
    ALCHEMY_API_KEY: "euSwyu6Yf-VQ3NJ32KHxDhHmTta7OvIe",
    // BLOCKNATIVE_KEY: REACT_APP_BLOCKNATIVE_KEY,
    BLOCKNATIVE_KEY: "5e6298d7-6c07-4571-87a1-e11b2fb6d6ce",
    // FORTMATIC_KEY: REACT_APP_FORTMATIC_KEY,
    FORTMATIC_KEY: "pk_test_886ADCAB855632AA",
    // PORTIS_KEY: REACT_APP_PORTIS_KEY,
    PORTIS_KEY: "b2b7586f-2b1e-4c30-a7fb-c2d1533b153b",
    // ZAPPER_API_KEY: REACT_APP_ZAPPER_API_KEY,
    ZAPPER_API_KEY: "96e0cc51-a62e-42ca-acee-910ea7d2a241",
    // YEARN_SUBGRAPH_KEY: REACT_APP_YEARN_SUBGRAPH_KEY,
    YEARN_SUBGRAPH_KEY: "",
  };
});
