import styled from "styled-components";

import { Text } from "@components/common";
import { InfoCard, ViewContainer } from "@components/app";
import { device } from "@themes/default";

const StyledViewContainer = styled(ViewContainer)`
  display: grid;
  grid-auto-rows: min-content;
  padding: ${({ theme }) => theme.card.paddingVariant};
  font-size: 1.6rem;
  line-height: 2.8rem;

  article {
    margin: 0 auto;
  }

  p {
    max-width: 60rem;
  }

  p + p {
    margin-top: 4rem;
  }

  @media ${device.mobile} {
    padding: ${({ theme }) => theme.card.padding};
    p {
      width: 100%;
    }
  }
`;

const StyledInfoCard = styled(InfoCard)`
  max-width: 100%;
  flex: 1;
`;

const StyledWrapper = styled.div`
  width: 85vw;
  margin: auto;
  text-transform: uppercase;
`;

// const StyledLink = styled.a`
//   white-space: initial;
//   text-decoration: underline;
//   color: inherit;
// `;

const StyledTitle = styled(Text)`
  font-size: 4rem;
  color: ${(props) => props.theme.colors.white};
`;

const StyledSubTitle = styled(Text)`
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.white};
`;

// const StyledSubSubTitle = styled(Text)`
//   font-size: 1.8rem;
//   color: ${(props) => props.theme.colors.white};
// `;

const StyledText = styled(Text)`
  line-height: 132%;
  color: ${(props) => props.theme.colors.zinc200};
`;

export const Disclaimer = () => {
  return (
    <StyledViewContainer>
      <div id="background-radial-gradient"></div>

      <StyledInfoCard
        header=""
        Component={
          <StyledWrapper>
            <StyledTitle>Terms of Service</StyledTitle>

            <br />
            <br />

            <StyledSubTitle>Updated: 05/18/22</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              THE INTERFACE IS A WEB APPLICATION WHICH MERELY PROVIDES A NON-EXCLUSIVE, PARTIAL USER INTERFACE TO THE
              PROTOCOL. THE OWNER/OPERATOR OF THE INTERFACE DOES NOT HAVE CUSTODY OVER YOUR ASSETS OR ACCESS TO YOUR
              PRIVATE KEY AND CANNOT INITIATE A TRANSFER OF DIGITAL ASSETS OR OTHERWISE ACCESS YOUR DIGITAL ASSETS. THE
              INTERFACE IS NOT A BROKER OR INTERMEDIARY AND IS IN NO WAY YOUR AGENT, ADVISOR, OR CUSTODIAN, AND WE DO
              NOT HAVE A FIDUCIARY RELATIONSHIP OR OBLIGATION TO YOU REGARDING ANY OTHER DECISIONS OR ACTIVITIES THAT
              YOU EFFECT WHEN USING YOUR WALLET OR OUR SERVICES. WE ARE NOT RESPONSIBLE FOR ANY ACTIVITIES THAT YOU
              ENGAGE IN WHEN USING YOUR WALLET, AND YOU SHOULD UNDERSTAND THE RISKS ASSOCIATED WITH PARTICIPATING IN
              DECENTRALIZED PROTOCOLS ON ANY BLOCKCHAIN. UNLESS EXPLICITLY PROVIDED IN WRITING, WE DO NOT HOST OR
              MAINTAIN ECOSYSTEM PARTNERS ACCESSIBLE ON OUR SERVICES AND DO NOT PARTICIPATE IN ANY TRANSACTIONS ON SUCH
              ECOSYSTEM PARTNERS, RECOMMEND, ENDORSE, OR OTHERWISE TAKE A POSITION ON YOUR USE OF THESE SERVICES.
            </StyledText>

            <br />
            <br />

            <StyledText>
              This Terms of Service Agreement (the “Terms” or “Agreement”) explains the terms and conditions by which
              you may access and use the Interface. Please read this Agreement carefully. This Agreement applies to you
              (“You”) as a user of the Interface, including all the products, services, tools, and information made
              available on asx.st or on asxprotocol.com. This Agreement contains a mandatory individual arbitration and
              class action/jury trial waiver provision that requires the use of arbitration on an individual basis to
              resolve disputes, rather than jury trials or class actions.
            </StyledText>

            <br />
            <br />

            <StyledText>
              To use the Interface, you must be able to form a legally binding contract online either as an individual
              or on behalf of a legal entity. To that end, you represent that, if you are agreeing to this Agreement on
              behalf of a legal entity, you have the legal authority to bind the company or other legal entity to this
              Agreement and you are at least 18 years old or the age of majority where you reside, whichever is older,
              can form a legally binding contract online, and have the full, right, power, and authority to enter into
              and to comply with the obligations under this Agreement.
            </StyledText>

            <br />
            <br />

            <StyledText>
              You are advised to check this Agreement periodically to familiarize yourself with any changes to the
              terms. We, in our sole discretion, reserve the right to make changes to our terms of services. Changes are
              binding on users of the Interface and will take effect immediately upon posting. As a user, you agree to
              be bound by any changes, variations, or modifications to our terms of service and your continued use of
              the Interface shall constitute acceptance of any such changes, variations, or modifications.
            </StyledText>

            <br />
            <br />

            <StyledText>
              By accessing or using the Interface, you signify that you have read, understand, and agree to be bound by
              this Agreement in its entirety. If you do not agree, you are not authorized to access or use the
              Interface.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>1. Interface</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              The Interface provides access to a decentralized protocol on several blockchains that allows suppliers and
              borrowers of digital assets to interact with the Protocol and transact using smart contracts (“Smart
              Contracts”).
            </StyledText>

            <br />
            <br />

            <StyledText>
              Using the Protocol may require that you pay a fee on the blockchain to perform a transaction. You
              acknowledge and agree that the Protocol has no control over any transactions, the method of payment of any
              transactions, or any actual payments of transactions. You must ensure that you have a sufficient balance
              to complete any transaction on the Protocol before initiating such transaction.
            </StyledText>

            <br />
            <br />

            <StyledText>
              You acknowledge and agree that we have no control over any transactions over the Protocol, the
              blockchains, the method of payment of any transactions or any actual payments of transactions.
              Accordingly, you must ensure that you have a sufficient balance of the applicable cryptocurrency tokens
              stored at your ASX Protocol-compatible wallet address (“Cryptocurrency Wallet”) to complete any
              transaction on the Protocol or the blockchains before initiating such transaction.
            </StyledText>

            <br />
            <br />

            <StyledText>
              When used on this Interface, the terms “debt”, “lend”, “borrow”, “collateral”, “credit”, “leverage”,
              “bank”, “borrow”, “yield”, “invest” and other similar terms are not meant to be interpreted literally.
              Rather, such terms are being used to draw rough, fuzzy-logic analogies between the heavily automated and
              mostly deterministic operations of a decentralized-finance smart contract system and the discretionary
              performance of traditional-finance transactions by people. When the Interface is used by users to access
              the Protocol and transact using Smart Contracts, there are no legal agreements, promises of payment, or
              courts of law, and therefore there are no debts, loans, or other traditional finance transactions
              involved.
            </StyledText>

            <br />
            <br />

            <StyledText>
              When the Vaults are used to “lend” or “deposit” tokens into a third-party smart-contract system, the
              situation is even less like traditional debt: the “borrowing” smart contract has not posted “collateral”
              and could malfunction or suffer a loss that results in complete or partial failure to return the
              “borrowed” tokens. In this case, the token “lenders” do not have a contractual remedy against the smart
              contract “borrower” or its creators -- the third-party smart contract is not a person, is usually not
              under the full control of any person or persons and may be impossible to pause or reverse. A
              malfunctioning, exploited or underperforming smart contract cannot be forced (in court or otherwise) to
              pay the “borrowed” tokens back.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Thus, the transactions you can effect through the Vaults or any of the decentralized finance systems on
              this website, while superficially similar to traditional financial transactions in some ways, are in fact
              very different. DeFi and TradFi each have unique costs and benefits, risks and protection mechanisms.
              Please bear this fact in mind when using this website, and do not use the Vaults or any other system
              described on this website without a sufficient understanding of their unique risks and how they differ
              from traditional financial transactions. The only way to fully understand such risks is to have a strong
              understanding of the relevant technical systems and the incentive design mechanisms they embody.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>2. Access</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              Access to the Interface is provided “as is” and on an “as available” basis only. We do not guarantee that
              the Interface, or any content on it, will always be available or uninterrupted. From time to time, access
              may be interrupted, suspended, or restricted, including because of a fault, error, unforeseen
              circumstances, or because we are carrying out planned maintenance.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We reserve the right to limit the availability of the Interface to any person, geographic area, or
              jurisdiction we so desire and/or to terminate your access to and use of the site, at any time and in our
              sole discretion.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We may remove or amend the content of the Interface at any time. However, some of the content may be out
              of date at any given time and we are under no obligation to update it. We do not guarantee that the
              Interface, or any content on it, will be free from errors or omissions.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We will not be liable to you for any loss or damage you may suffer as a result of the Interface being
              unavailable at any time for any reason.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>3. Eligibility</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              To access or use the Interface, you must be able to form a legally binding contract with us. Accordingly,
              you represent that you are at least eighteen years old and have the full right, power, and authority to
              enter into and comply with the terms and conditions of this Agreement on behalf of yourself and any
              company or legal entity for which you may access or use the Interface. You further represent that you are
              not a citizen, resident, or member of any jurisdiction or group that is subject to economic sanctions, or
              where your use of the Interface would be illegal or otherwise violate any applicable law. You further
              represent that your access and use of the Interface will fully comply with all applicable laws and
              regulations, and that you will not access or use the Interface to conduct, promote, or otherwise
              facilitate any illegal activity.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>4. Modification of this Agreement</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              We reserve the right, in our sole discretion, to modify this Agreement from time to time. If we make any
              modifications, we will notify you by updating the date at the top of the Agreement and by maintaining a
              current version of the Agreement at asx.st/#/terms. All modifications will be effective when they are
              posted, and your continued use of the Interface will serve as confirmation of your acceptance of those
              modifications. If you do not agree with any modifications to this Agreement, you must immediately stop
              accessing and using the Interface.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>5. Privacy</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              Your privacy is important to us. Although we do not collect your data, we cannot guarantee that
              unauthorized third parties will never be able to obtain or use your data or aggregate data for improper
              purposes. By accessing and using the Interface, you acknowledge that we are not responsible for any of
              these variables or risks, do not own or control the Protocol, and cannot be held liable for any improperly
              accessed data, whether resulting in losses that you experience while accessing or using the Interface or
              otherwise.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>6. Prohibited Activity</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              You agree not to engage in, or attempt to engage in, any of the following categories of prohibited
              activity in relation to your access and use of the Interface:
            </StyledText>

            <br />

            <StyledText>
              <ul>
                <li>
                  <u>Intellectual Property Infringement</u>. Activity that infringes on or violates any copyright,
                  trademark, service mark, patent, right of publicity, right of privacy, or other proprietary or
                  intellectual property rights under the law.
                </li>

                <br />

                <li>
                  <u>Cyberattack</u>. Activity that seeks to interfere with or compromise the integrity, security, or
                  proper functioning of any computer, server, network, personal device, or other information technology
                  system, including (but not limited to) the deployment of viruses and denial of service attacks.
                </li>

                <br />

                <li>
                  <u>Fraud and Misrepresentation</u>. Activity that seeks to defraud us or any other person or entity,
                  including (but not limited to) providing any false, inaccurate, or misleading information in order to
                  unlawfully obtain the property of another.
                </li>

                <br />

                <li>
                  <u>Market Manipulation</u>. Activity that violates any applicable law, rule, or regulation concerning
                  the integrity of trading markets, including (but not limited to) the manipulative tactics commonly
                  known as spoofing and wash trading.
                </li>

                <br />

                <li>
                  <u>Other Harmful Acts</u>. Attempts to engage in or engage in, any potentially harmful acts that are
                  directed against ASX Labs, including but not limited to violating or attempting to violate any
                  security features of ASX Labs, using manual or automated software or other means to access, “scrape,”
                  “crawl” or “spider” any pages contained in or on the Interface, introducing viruses, worms, or similar
                  harmful code into the Interface, or interfering or attempting to interfere with use of the Interface
                  by any other user, host or network, including by means of overloading, “flooding,” “spamming,” “mail
                  bombing,” or “crashing” the Interface or any other ASX Labs properties.
                </li>

                <br />

                <li>
                  <u>Any Other Unlawful Conduct</u>. Activity that violates any applicable Law, including, without
                  limitation, any relevant and applicable anti-money laundering and anti-terrorist financing laws and
                  any relevant and applicable privacy and data collection laws, in each case as may be amended.
                </li>
              </ul>
            </StyledText>

            <br />

            <StyledSubTitle>7. No Professional Advice</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              All information provided by the Interface is for informational purposes only and should not be construed
              as professional advice. In particular, the content and materials available on the Interface does not
              constitute any form of advice or recommendation by us, should not be regarded as an offer, solicitation,
              invitation or recommendation to buy or sell investments, securities or any other financial services and is
              not intended to be relied upon by you in making any specific investment or other decisions. You should not
              take, or refrain from taking, any action based on any information contained in the Interface. Before you
              make any financial, legal, or other decisions involving the Interface, you should seek independent
              professional advice from an individual who is licensed and qualified in the area for which such advice
              would be appropriate.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Nothing included in the Interface constitutes an offer or solicitation to sell, or distribution of,
              investments and related services to anyone in any jurisdiction.
            </StyledText>

            <br />
            <br />

            <StyledText>
              From time to time, reference may be made to data we have gathered. These references may be selective or,
              may be partial. As markets change continuously, previously published information and data may not be
              current and should not be relied upon.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Any reference to any Rate on the Interface is denominated in terms of USDC or other digital assets or
              tokens, as applicable, not USD or other fiat currency. The Rate is a forward-looking projection based on a
              good faith belief of how to reasonably project results over the relevant period, but such belief is
              subject to numerous assumptions, risks and uncertainties (including smart contract security risks and
              third-party actions) which could result in a materially different (lower or higher) token-denominated
              Rate.
            </StyledText>

            <br />
            <br />

            <StyledText>
              The Rate is not a promise, guarantee, or undertaking on the part of any person or group of persons, but
              depends entirely on the results of operation of smart contracts and other autonomous systems (including
              third-party systems) and how third parties interact with those systems after the time of your deposit.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Even if the Rate is achieved as projected, you may still suffer a financial loss in fiat-denominated terms
              if the fiat-denominated value of the relevant tokens (your deposit and any tokens allocated or distributed
              to you pursuant to the Rate) declines during the deposit period.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>8. Third-Party Links</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              The Interface may contain hyperlinks or references to third party websites. Any such hyperlinks or
              references are provided for your information and convenience only. We have no control over third party
              websites and accept no legal responsibility for any content, material or information contained in them.
              The display of any hyperlink and reference to any third-party website does not mean that we endorse that
              third party's website, products or services. Your use of a third-party site may be governed by the terms
              and conditions of that third-party site.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>9. No Warranties</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              The Interface is provided on an “AS IS” and “AS AVAILABLE” basis. To the fullest extent permitted by law,
              we disclaim any representations and warranties of any kind, whether express, implied, or statutory,
              including (but not limited to) the warranties of merchantability and fitness for a particular purpose. You
              acknowledge and agree that your use of the Interface is at your own risk. We do not represent or warrant
              that access to the Interface will be continuous, uninterrupted, timely, or secure; that the information
              contained in the Interface will be accurate, reliable, complete, or current; or that the Interface will be
              free from errors, defects, viruses, or other harmful elements. No advice, information, or statement that
              we make should be treated as creating any warranty concerning the Interface. We do not endorse, guarantee,
              or assume responsibility for any advertisements, offers, or statements made by third parties concerning
              the Interface.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>10. No Fiduciary Duties</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              This Agreement is not intended to, and does not, create or impose any fiduciary duties on us. To the
              fullest extent permitted by law, you acknowledge and agree that we owe no fiduciary duties or liabilities
              to you or any other party, and that to the extent any such duties or liabilities may exist at law or in
              equity, those duties and liabilities are hereby irrevocably disclaimed, waived, and eliminated. You
              further agree that the only duties and obligations that we owe you are those set out expressly in this
              Agreement.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>11. Assumption of Risk</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              By accessing and using the Interface, you represent that you understand the inherent risks associated with
              using cryptographic and blockchain-based systems, and that you have a working knowledge of the usage and
              intricacies of digital assets. You further understand that the markets for these digital assets are highly
              volatile due to factors including (but not limited to) adoption, speculation, technology, security, and
              regulation. You acknowledge that the cost and speed of transacting with cryptographic and blockchain-based
              systems are variable and may increase dramatically at any time. You further acknowledge the risk that your
              digital assets may lose some or all of their value while they are supplied to the Protocol. If you borrow
              digital assets from the Protocol, you will have to supply digital assets of your own as collateral. If
              your collateral declines in value such that it is no longer sufficient to secure the amount that you
              borrowed, others may interact with the Protocol to seize your collateral in a liquidation event. You
              further acknowledge that we are not responsible for any of these variables or risks, do not own or control
              the Protocol, and cannot be held liable for any resulting losses that you experience while accessing or
              using the Interface.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We make no warranties as to the markets on which digital assets are transferred, purchased, or traded. You
              are solely responsible for determining what, if any, taxes apply to your digital asset transactions. We
              are not responsible for determining the taxes that apply to your transactions.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We do not store, send, or receive digital assets or funds. This is because digital assets exist only by
              virtue of the ownership record maintained on its supporting blockchain. Any transfer of digital assets
              occurs within the decentralized Protocol and the blockchains not in the Interface. We cannot assist you to
              cancel or otherwise modify any transaction or transaction details. There are no warranties or guarantees
              that a transfer initiated via the Interface will successfully transfer title or right in any digital
              asset.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Accordingly, you understand and agree to assume full responsibility for all of the risks of accessing and
              using the Interface and interacting with the Protocol.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>12. Release of Claims</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              You expressly agree that you assume all risks in connection with your access and use of the Interface and
              your interaction with the Protocol. You further expressly waive and release us from any and all liability,
              claims, causes of action, or damages arising from or in any way relating to your use of the Interface and
              your interaction with the Protocol. If you are a California resident, you waive the benefits and
              protections of California Civil Code § 1542, which provides: “[a] general release does not extend to
              claims that the creditor or releasing party does not know or suspect to exist in his or her favor at the
              time of executing the release and that, if known by him or her, would have materially affected his or her
              settlement with the debtor or released party.”
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>13. Indemnity</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              You agree to hold harmless, release, defend, and indemnify us and our officers, directors, employees,
              contractors, agents, affiliates, and subsidiaries from and against all claims, damages, obligations,
              losses, liabilities, costs, and expenses arising from: (a) your access and use of the Interface; (b) your
              violation of any term or condition of this Agreement, the right of any third party, or any other
              applicable law, rule, or regulation; and (c) any other party's access and use of the Interface with your
              assistance or using any device or account that you own or control.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>14. Limitation of Liability</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              Under no circumstances shall we or any of our officers, directors, employees, contractors, agents,
              affiliates, or subsidiaries be liable to you for any indirect, punitive, incidental, special,
              consequential, or exemplary damages, including (but not limited to) damages for loss of profits, goodwill,
              use, data, or other intangible property, arising out of or relating to any access or use of the Interface,
              nor will we be responsible for any damage, loss, or injury resulting from hacking, tampering, or other
              unauthorized access or use of the Interface or the information contained within it.
            </StyledText>

            <br />
            <br />

            <StyledText>
              We assume no liability or responsibility for any: (a) errors, mistakes, or inaccuracies of content; (b)
              personal injury or property damage, of any nature whatsoever, resulting from any access or use of the
              Interface; (c) unauthorized access or use of any secure server or database in our control, or the use of
              any information or data stored therein; (d) interruption or cessation of function related to the
              Interface; (e) bugs, viruses, trojan horses, or the like that may be transmitted to or through the
              Interface; (f) errors or omissions in, or loss or damage incurred as a result of the use of, any content
              made available through the Interface; and (g) the defamatory, offensive, or illegal conduct of any third
              party. Under no circumstances shall we or any of our officers, directors, employees, contractors, agents,
              affiliates, or subsidiaries be liable to you for any claims, proceedings, liabilities, obligations,
              damages, losses, or costs in an amount exceeding the amount you paid to us in exchange for access to and
              use of the Interface, or $100.00, whichever is greater.
            </StyledText>

            <br />
            <br />

            <StyledText>
              This limitation of liability applies regardless of whether the alleged liability is based on contract,
              tort, negligence, strict liability, or any other basis, and even if we have been advised of the
              possibility of such liability. Some jurisdictions do not allow the exclusion of certain warranties or the
              limitation or exclusion of certain liabilities and damages. Accordingly, some of the disclaimers and
              limitations set forth in this Agreement may not apply to you. This limitation of liability shall apply to
              the fullest extent permitted by law.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>15. Dispute Resolution</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              We will use our best efforts to resolve any potential disputes through informal, good faith negotiations.
              If a potential dispute arises, you must contact us by sending an email to legal@asx.st so that we can
              attempt to resolve it without resorting to formal dispute resolution. If we aren't able to reach an
              informal resolution within sixty days of your email, then you and we both agree to resolve the potential
              dispute according to the process set forth below.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Any claim or controversy arising out of or relating to the Interface, this Agreement, including any
              question regarding this Agreement's existence, validity or termination, or any other acts or omissions for
              which you may contend that we are liable, including (but not limited to) any claim or controversy as to
              arbitrability (“Dispute”), shall be referred to and finally resolved by arbitration in Saint Kitts and
              Nevis in accordance with the Arbitration Rules of the country.
            </StyledText>

            <br />
            <br />

            <StyledText>
              You understand that you are required to resolve all Disputes by binding arbitration. The arbitration shall
              be held on a confidential basis before one or three arbitrators, who shall be selected pursuant to KNA
              Rules. The seat of the arbitration shall be determined by the arbitrator(s); the arbitral proceedings
              shall be conducted in English. The applicable law shall be Saint Kitts and Nevis law.
            </StyledText>

            <br />
            <br />

            <StyledText>
              Unless we agree otherwise, the arbitrator may not consolidate your claims with those of any other party.
              Any judgment on the award rendered by the arbitrator may be entered in any court of competent
              jurisdiction.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>16. Class Action and Jury Trial Waiver</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              You must bring any and all Disputes against us in your individual capacity and not as a plaintiff in or
              member of any purported class action, collective action, private attorney general action, or other
              representative proceeding. This provision applies to class arbitration. You and we both agree to waive the
              right to demand a trial by jury.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>17. Governing Law</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              You agree that the laws of Saint Kitts and Nevis, without regard to principles of conflict of laws, govern
              this Agreement and any Dispute between you and us. You further agree that the Interface shall be deemed to
              be based solely in Saint Kitts and Nevis, and that although the Interface may be available in other
              jurisdictions, its availability does not give rise to general or specific personal jurisdiction in any
              forum outside of Saint Kitts and Nevis.
            </StyledText>

            <br />
            <br />

            <StyledSubTitle>18. Miscellaneous</StyledSubTitle>

            <br />
            <br />

            <StyledText>
              We may perform any of our obligations, and exercise any of the rights granted to us under this Agreement,
              through a third-party. We may assign any or all our rights and obligations under this Agreement to any
              third-party.
            </StyledText>

            <br />
            <br />

            <StyledText>
              If any clause or part of any clause of this Agreement is found to be void, unenforceable or invalid, then
              it will be severed from this Agreement, leaving the remainder in full force and effect, provided that the
              severance has not altered the basic nature of this Agreement.
            </StyledText>

            <br />
            <br />

            <StyledText>
              No single or partial exercise, or failure or delay in exercising any right, power or remedy by us shall
              constitute a waiver by us of, or impair or preclude any further exercise of, that or any right, power or
              remedy arising under these terms and conditions or otherwise.
            </StyledText>

            <br />
            <br />

            <StyledText>
              If any of the provisions in this Agreement is found to be illegal, invalid or unenforceable by any court
              of competent jurisdiction, the remainder shall continue in full force and effect.
            </StyledText>

            <br />
            <br />

            <StyledText>
              All disclaimers, indemnities and exclusions in this Agreement shall survive termination of the Agreement
              and shall continue to apply during any suspension or any period during which the Interface is not
              available for you to use for any reason whatsoever.
            </StyledText>
          </StyledWrapper>
        }
      />
    </StyledViewContainer>
  );
};
