import { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { ChevronTxIcon, ErrorIcon, Icon, Text, WarningIcon } from "@components/common";
import { useAppTranslation } from "@hooks";
import { device } from "@themes/default";

const StyledArrowIcon = styled(Icon)<{ isExpanded: boolean }>`
  width: 1.6rem;
  fill: currentColor;
  flex-shrink: 0;
  transform: rotate(180deg);
  transition: transform 200ms ease-in-out;

  ${({ isExpanded }) =>
    isExpanded &&
    `
    transform: rotate(0);
  `}
`;
const StyledIcon = styled(Icon)`
  width: 3.6rem;
  padding: 0 0.2rem;
  flex-shrink: 1;
  fill: ${({ theme }) => theme.colors.red};
`;

const StyledTextTitle = styled(Text)`
  flex-grow: 1;
  white-space: no-wrap;
  color: inherit;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;

  @media ${device.mobileS} {
    font-size: 1.2rem;
  }
`;

const StyledTextDescription = styled(Text)`
  color: inherit;
  max-width: 100%;
  font-size: 1.2rem;
  user-select: text;
`;

const StyledTxError = styled.div<{ errorType?: ErrorType }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: calc(${({ theme }) => theme.txModal.gap} * 1.6);
  padding: calc(${({ theme }) => theme.txModal.gap} * 1) calc(${({ theme }) => theme.txModal.gap} * 1.6);
  font-size: 1.4rem;
  border-radius: ${({ theme }) => theme.lgRadius};
  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  ${({ errorType, theme }) =>
    errorType === "warning" &&
    `
    background-color: ${theme.colors.bgRed};
  `}
`;

const StyledTxErrorTitle = styled.div`
  display: flex;
  flex: 1;
  gap: 0.8rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.red};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

const StyledTxErrorDescription = styled.div<{ isExpanded: boolean; errorType?: ErrorType }>`
  max-height: 0;
  overflow-y: scroll;
  transition: margin 200ms ease-in-out, max-height 200ms ease-in-out;

  ${({ isExpanded }) =>
    isExpanded &&
    `
    max-height: 8rem;
    margin: .8rem 0;
  `}

  ::-webkit-scrollbar {
    width: 0.2rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.bgRed}80;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.bgRed};
  }

  ${({ errorType, theme }) =>
    errorType === "warning" &&
    `
      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.colors.bgRed}80;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.colors.bgRed};
      }
  `}

  @media ${device.mobileS} {
    gap: calc(${({ theme }) => theme.txModal.gap} * 0.8);
    padding: calc(${({ theme }) => theme.txModal.gap} * 1) calc(${({ theme }) => theme.txModal.gap} * 1.6);
  }
`;

type ErrorType = "error" | "warning";
export interface TxErrorProps {
  errorDescription?: string;
  errorTitle?: string;
  errorType?: ErrorType;
}

export const TxError: FC<TxErrorProps> = ({ errorTitle, errorDescription, errorType, children, ...props }) => {
  const { t } = useAppTranslation("common");
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    // NOTE Clean state once error message changes
    setIsExpanded(false);
  }, [errorTitle, errorDescription]);

  const onExpandClick = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  return (
    <StyledTxError errorType={errorType} {...props}>
      <StyledTxErrorTitle onClick={errorDescription ? onExpandClick : undefined}>
        <StyledIcon Component={errorType === "warning" ? WarningIcon : ErrorIcon} />

        <StyledTextTitle>{errorTitle || t("errors.unknown")}</StyledTextTitle>

        {/* {errorDescription && <StyledArrowIcon Component={ChevronTxIcon} isExpanded={isExpanded} />} */}
      </StyledTxErrorTitle>

      {/* {errorDescription && (
        <StyledTxErrorDescription isExpanded={isExpanded} errorType={errorType}>
          <StyledTextDescription>{errorDescription}</StyledTextDescription>
        </StyledTxErrorDescription>
      )} */}
    </StyledTxError>
  );
};
