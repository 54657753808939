import { FC } from "react";
import styled from "styled-components";

import { Icon, CloseIcon } from "../Icon";

const ModalHeader = styled.div`
  font-size: 1.8rem;
`;

const CloseModal = styled.div`
  padding: 0.4rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
  transition: opacity ${({ theme }) => theme.transition.eo250};

  :hover {
    opacity: ${({ theme }) => theme.opac64};
  }
`;

const StyledModal = styled.div`
  overflow: hidden;
  overflow-y: auto;
  padding: 1.6rem;
  background: ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ theme }) => theme.smRadius};
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  pointer-events: all;
  z-index: 1;
  width: 32rem;
  height: 32rem;
  max-width: 85%;
  max-height: 85%;
`;

export interface ModalProps {
  className?: string;
  header?: string;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({ className, header, onClose, children, ...props }) => {
  let closeButton;

  if (onClose) {
    closeButton = (
      <CloseModal onClick={onClose}>
        <Icon Component={CloseIcon} />
      </CloseModal>
    );
  }
  return (
    <StyledModal className={className} {...props}>
      {closeButton}
      <ModalHeader>{header}</ModalHeader>

      {children}
    </StyledModal>
  );
};
