import styled from "styled-components";

import { Address, Network } from "@types";
import { DefaultscanIcon, EtherscanIcon, FtmscanIcon, ArbiscanIcon, Icon } from "@components/common";

interface ScanNetworkIconProps {
  currentNetwork?: Network;
  blockExplorerUrl?: string;
  address: Address;
}

const IconScan = styled(Icon)`
  fill: ${({ theme }) => theme.colors.zinc200};
  padding-left: 0.4rem;
  padding-bottom: 0.4rem;
  &:hover {
    cursor: pointer;
  }
`;

export const ScanNetworkIcon = ({ currentNetwork, blockExplorerUrl, address }: ScanNetworkIconProps) => {
  const handleScanSiteExplorer = () => {
    return window.open(`${blockExplorerUrl}/address/${address}`);
  };

  const selectScanIcon = () => {
    switch (currentNetwork) {
      case "mainnet":
        return EtherscanIcon;
      case "fantom":
        return FtmscanIcon;
      case "arbitrum":
        return ArbiscanIcon;
      default:
        return DefaultscanIcon;
    }
  };

  return <IconScan Component={selectScanIcon()} onClick={handleScanSiteExplorer} />;
};
