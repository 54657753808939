import { ElementType, useEffect } from "react";
import styled from "styled-components";

import { useAppDispatch, useAppSelector, useWindowDimensions } from "@hooks";
import { SettingsActions, SettingsSelectors } from "@store";
import { WalletIcon, VaultIcon, LabsIcon, SettingsIcon, IronBankIcon } from "@components/common";

import { NavSidebar } from "./NavSidebar";
import { NavTabbar } from "./NavTabbar";

export interface NavigationLink {
  to: string;
  text: string;
  icon: ElementType;
  hideMobile?: boolean;
  external?: boolean;
  optional?: boolean;
}

const StyledNavigation = styled.div`
  display: none;
`;

const navLinks: NavigationLink[] = [
  {
    to: "/",
    text: "",
    // icon: HomeIcon,
    icon: WalletIcon,
  },
  {
    to: "/wallet",
    text: "",
    icon: WalletIcon,
  },
  {
    to: "/vaults",
    text: "",
    icon: VaultIcon,
  },
  {
    to: "/labs",
    text: "",
    icon: LabsIcon,
    optional: true,
  },
  {
    to: "https://app.ib.xyz",
    text: "",
    icon: IronBankIcon,
    external: true,
    optional: true,
  },
  {
    to: "/settings",
    text: "",
    icon: SettingsIcon,
    // hideMobile: true,
  },
];

interface NavigationProps {
  hideOptionalLinks?: boolean;
}

export const Navigation = ({ hideOptionalLinks }: NavigationProps) => {
  const { isMobile, isTablet, isDesktop } = useWindowDimensions();
  const displayLinks = navLinks.filter((link) => !(link.optional && hideOptionalLinks));

  // NOTE Auto collapse sidenav on mobile
  const dispatch = useAppDispatch();
  const collapsedSidebar = useAppSelector(SettingsSelectors.selectSidebarCollapsed);

  useEffect(() => {
    if ((isTablet || isMobile) && !collapsedSidebar) {
      dispatch(SettingsActions.closeSidebar());
    }
    if (isDesktop && !isTablet && collapsedSidebar) {
      dispatch(SettingsActions.openSidebar());
    }
  }, [isMobile, isTablet, isDesktop]);

  return (
    <StyledNavigation>
      {isMobile ? <NavTabbar navLinks={displayLinks} /> : <NavSidebar navLinks={displayLinks} />}
    </StyledNavigation>
  );
};
