import { FC } from "react";
import styled from "styled-components";

import { styledSystem, StyledSystemProps } from "../styledSystem";

export interface SpinnerLoadingProps extends StyledSystemProps {}

const StyledSpinnerLoading = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  font-size: 2.4rem;

  ${styledSystem}
`;

const StyledLoader = styled.div`
  animation: dots-loading 1s infinite ease-in-out alternate;
  animation-delay: 0.4s;

  background: currentColor;
  border-radius: ${({ theme }) => theme.smRadius};
  display: inline-block;
  height: 1rem;
  width: 1rem;
  box-sizing: border-box;
  opacity: ${({ theme }) => theme.opac48};
  transform: scale(0.8, 0.8);

  &:first-child {
    animation-delay: 0s;
  }

  &:last-child {
    animation-delay: 1s;
  }

  @keyframes dots-loading {
    0% {
      opacity: ${({ theme }) => theme.opac48};
      transform: scale(0.8, 0.8);
    }

    50%,
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

export const SpinnerLoading: FC<SpinnerLoadingProps> = ({ ...props }) => (
  <StyledSpinnerLoading {...props}>
    <StyledLoader />
    <StyledLoader />
    <StyledLoader />
  </StyledSpinnerLoading>
);
