import { ElementType, FC, InputHTMLAttributes } from "react";
import styled from "styled-components";

import { Icon } from "../Icon";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  color?: string;
  Icon?: ElementType;
}

const StyledInputContainer = styled.div`
  --input-placeholder: ${(props) => props.theme.colors.zinc500};

  display: flex;
  background: ${(props) => props.theme.colors.darkGray};
  /* box-shadow: inset 0 0 0.1rem 0.1rem ${({ theme }) => theme.colors.bgDark}; */
  border-radius: ${({ theme }) => theme.xxlRadius};
  padding: 1.2rem 1.6rem;
  margin-top: 1.6rem;

  &:focus-within {
    --input-placeholder: ${(props) => props.theme.colors.zinc500};

    /* box-shadow: inset 0 0 0.2rem 0.2rem ${({ theme }) => theme.colors.bgDark}; */
  }
`;

const StyledInput = styled.input`
  background: transparent;
  color: ${(props) => props.theme.colors.zinc200};
  outline: none;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  border: none;
  text-align: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding-left: 0.6rem;
  text-transform: uppercase;

  &::placeholder {
    color: var(--input-placeholder);
  }
`;

const StyledIcon = styled(Icon)`
  width: 1.6rem;
  margin-right: 0.4rem;
  fill: inherit;
`;

export const Input: FC<InputProps> = ({ className, color, placeholder, Icon, ...props }) => (
  <StyledInputContainer className={className} color={color} {...props}>
    {Icon && <StyledIcon Component={Icon} />}
    <StyledInput placeholder={placeholder} aria-label={placeholder} />
  </StyledInputContainer>
);
