import styled from "styled-components";

import { ReactComponent as AlchemyCertified } from "@assets/images/alchemy-certified.svg";
import {
  // Icon,
  // MediumIcon,
  Link,
  // TwitterIcon,
  // DiscordIcon,
  // GithubIcon,
  // TelegramIcon,
  // RedditIcon,
} from "@components/common";
import { useAppTranslation } from "@hooks";
import { device } from "@themes/default";

interface FooterProps {
  className?: string;
}

const footerLinks = [
  {
    link: "https://mint.asx.st/",
    name: "GENESIS",
  },

  {
    link: "https://mirror.xyz/asxlabs.eth",
    name: "MIRROR",
    ariaLabel: "READ OUR MIRROR POSTS",
  },

  {
    link: "https://twitter.com/asxlabs",
    name: "TWITTER",
    ariaLabel: "FOLLOW US ON TWITTER",
  },

  {
    link: "https://t.me/asxprotocol",
    name: "TELEGRAM",
    ariaLabel: "JOIN US ON TELEGRAM",
  },

  // {
  //   link: "https://docs.asx.st/",
  //   name: "DOCS",
  // },

  {
    link: "https://snapshot.org/#/asxprotocol.eth",
    name: "GOVERNANCE",
    ariaLabel: "PARTICIPATE IN ASX GOVERNANCE",
  },

  {
    link: "/#/terms",
    name: "TERMS",
    ariaLabel: "READ ASX TERMS",
  },
];

// const socialLinks = [
//   {
//     // link: 'https://twitter.com/...',
//     // icon: TwitterIcon,
//   },
//   {
//     // link: 'https://github.com/...',
//     // icon: GithubIcon,
//   },
//   {
//     // link: 'https://discord.xxx.xxx',
//     // icon: DiscordIcon,
//   },
//   {
//     // link: 'https://medium.com/...',
//     // icon: MediumIcon,
//   },
//   {
//     // link: 'https://t.me/...',
//     // icon: TelegramIcon,
//   },
//   {
//     // link: 'https://reddit.com/r/...',
//     // icon: RedditIcon,
//   },
// ];

// const SocialSectionIconSize = "2.4rem";

// const SocialSection = styled.div`
//   --icon-size: 3rem;

//   // display: grid;
//   display: none;
//   grid-template-columns: repeat(auto-fit, var(--icon-size));
//   align-items: center;
//   justify-content: flex-end;
//   grid-gap: ${({ theme }) => theme.lgLayoutPadding};
//   width: 100%;
//   min-width: calc(${SocialSectionIconSize} * ${socialLinks.length} / 2 + 3rem);
// `;

const LinkSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.card.padding};
  row-gap: 1rem;
  flex: 1;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.white};

  @media ${device.tabletL} {
    justify-content: center;
  }

  @media ${device.mobile} {
    column-gap: calc(${({ theme }) => theme.card.padding} / 2);
  }

  @media ${device.mobileS} {
    column-gap: calc(${({ theme }) => theme.card.padding} / 4);
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  padding: 0 ${({ theme }) => theme.smLayoutPadding};

  @media ${device.mobileL} {
    padding: 0 ${({ theme }) => theme.xxsLayoutPadding};
  }

  @media ${device.mobile} {
    font-size: 1.2rem;
  }

  @media ${device.mobileS} {
    font-size: 1rem;
  }
`;

const StyledIconLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;

// const StyledIcon = styled(Icon)`
//   width: var(--icon-size);
//   height: var(--icon-size);
//   fill: ${({ theme }) => theme.colors.zinc200};
// `;

// const LogoSection = styled.a`
//   display: flex;
//   justify-content: flex-end;
// `;

const AlchemyLogo = styled(AlchemyCertified)`
  width: 18rem;
  fill: ${({ theme }) => theme.colors.zinc200};
`;

const StyledFooter = styled.footer`
  display: grid;
  // grid-template-columns: 1fr 3fr;
  // align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 2.4rem;
  max-width: ${({ theme }) => theme.globalMaxWidth};
  margin-top: ${({ theme }) => theme.xxlLayoutPadding};

  ${StyledLink},
  ${StyledIconLink},
  ${AlchemyLogo} {
    transition: filter ${({ theme }) => theme.transition.eo250};
    filter: opacity(64%);

    &:hover {
      filter: opacity(100%);
    }
  }
`;

// const socialIcons = (
//   <SocialSection>
//     {socialLinks.map((social, index) => {
//       return (
//         <StyledIconLink href={social.link} target="_blank" key={index}>
//           <StyledIcon Component={social.icon} />
//         </StyledIconLink>
//       );
//     })}
//   </SocialSection>
// );

export const Footer = ({ className }: FooterProps) => {
  const { t } = useAppTranslation("common");

  return (
    <StyledFooter className={className}>
      {/* {socialIcons} */}

      <LinkSection>
        {footerLinks.map((footerLink) => (
          <StyledLink external to={footerLink.link} key={footerLink.name}>
            {/* {t(`footer.links.${footerLink.name}`)} */}
            {t(`${footerLink.name}`)}
          </StyledLink>
        ))}
      </LinkSection>

      {/* <SocialSection>
        {socialLinks.map((social, index) => (
          <StyledIconLink external to={social.link} key={index} aria-label={social.ariaLabel}>
            <StyledIcon Component={social.icon} />
          </StyledIconLink>
        ))}
      </SocialSection> */}
    </StyledFooter>
  );
};
