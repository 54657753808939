import { FC } from "react";
import styled from "styled-components";

import { Icon, StatusArrowIcon } from "@components/common";

export type TxArrowStatusTypes = "preparing" | "firstPending" | "secondPreparing" | "secondPending";

export interface TxArrowStatusProps {
  status?: TxArrowStatusTypes;
}

const IconBlur = styled(Icon)`
  position: absolute;
  transition: filter ${({ theme }) => theme.transition.eo250};
`;

const StyledTxArrowStatus = styled.div<{ status?: TxArrowStatusTypes }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  flex: 1;
  padding: 1rem;

  ${Icon} {
    height: 3.6rem;
    fill: inherit;
    flex-shrink: 1;
    transition: fill ${({ theme }) => theme.transition.eo250};
  }
  ${({ status, theme }) => {
    let arrowColor = theme.colors.zinc200;
    let blur = false;
    let animation = false;

    if (status === "preparing") {
      arrowColor = theme.colors.zinc200;
    } else if (status === "firstPending") {
      arrowColor = theme.colors.zinc200;
      blur = true;
      animation = true;
    } else if (status === "secondPreparing") {
      arrowColor = theme.colors.zinc200;
    } else if (status === "secondPending") {
      arrowColor = theme.colors.zinc200;
      blur = true;
      animation = true;
    }
    const animationCss = animation ? "animation: pulse 4s ease-in-out alternate infinite;" : undefined;

    const css = `
      fill: ${arrowColor};
      ${animationCss};
    `;

    if (blur) {
      return `
        ${css};

        ${IconBlur} {
          filter: blur(4rem);
        }
      `;
    } else {
      return css;
    }
  }}

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: ${({ theme }) => theme.opac48};
    }

    100% {
      opacity: 1;
    }
  }
`;

export const TxArrowStatus: FC<TxArrowStatusProps> = ({ status, children, ...props }) => (
  <StyledTxArrowStatus status={status} {...props}>
    <Icon Component={StatusArrowIcon} />
    <IconBlur Component={StatusArrowIcon} />
  </StyledTxArrowStatus>
);
