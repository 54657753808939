import styled from "styled-components";

import { Button, Icon, WarningFilledIcon } from "@components/common";
import { device } from "@themes/default";

const ActionButtonsContainer = styled.div<{ actions: number }>`
  display: flex;
  align-items: center;
  // grid-template-columns: repeat(${({ actions }) => actions}, 1fr);
  gap: ${({ theme }) => theme.card.padding};
  min-width: 100%;
`;

const AlertIcon = styled(Icon)`
  width: 1.6rem;
  fill: ${({ theme }) => theme.colors.zinc200};
`;

const AlertButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.smRadius};
  background: ${({ theme }) => theme.colors.darkGray};
  box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.zinc300};
  width: 2.8rem;
  height: 2.8rem;
`;

const ActionButton = styled(Button)<{ hide?: boolean }>`
  background: ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.zinc200};
  padding: 1.8rem;
  min-width: 28rem;

  @media ${device.mobileS} {
    min-width: 84vw;
    margin-left: 0;
    margin-right: 0;
  }

  @media ${device.mobileXS} {
  }

  ${({ hide }) => hide && `visibility: hidden;`}

  &[disabled],
  &.disabled {
  }
`;

interface ActionButtonsProps {
  actions: Array<{
    name: string;
    handler: () => void;
    disabled?: boolean;
    hide?: boolean;
  }>;
  alert?: string;
}

export const ActionButtons = ({ actions, alert }: ActionButtonsProps) => (
  <ActionButtonsContainer actions={actions.length}>
    {alert && (
      <AlertButton
        title={alert}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AlertIcon Component={WarningFilledIcon} />
      </AlertButton>
    )}

    {actions.map(({ name, handler, disabled, hide, ...props }) => (
      <ActionButton
        className="action-button"
        data-testid={`action-${name.toLowerCase()}`}
        key={`action-${name}`}
        onClick={(e: Event) => {
          e.stopPropagation();
          handler();
        }}
        disabled={disabled}
        hide={hide}
        {...props}
      >
        {name}
      </ActionButton>
    ))}
  </ActionButtonsContainer>
);
