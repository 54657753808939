import { FC } from "react";
import styled from "styled-components";

import { Icon, CloseIcon } from "@components/common";

export interface TxContainerProps {
  header?: string;
  onClose?: () => void;
}

const StyledIcon = styled(Icon)`
  fill: inherit;
  width: 1.6rem;
  height: 1.6rem;
`;

const HeaderAction = styled.div`
  padding: 0.4rem 0.2rem;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.white};
  transition: opacity ${({ theme }) => theme.transition.eo250};

  :hover {
    opacity: ${({ theme }) => theme.opac64};
  }
`;

const TxHeaderActions = styled.div`
  display: flex;
  align-items: center;
  margin: -0.2rem;
`;

const TxHeaderTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
`;

const TxContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.card.padding};
  position: relative;
  border-radius: ${({ theme }) => theme.smRadius};
  flex: 1;
`;

const TxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;
  margin: 0 0.4rem 0.4rem 0.4rem;
`;

const StyledTxContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: calc(${({ theme }) => theme.txModal.gap} * 1.2);
  margin: ${({ theme }) => theme.xxsLayoutPadding};
  width: calc(100% - ${({ theme }) => theme.smLayoutPadding});
  color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.xlRadius};
  gap: ${({ theme }) => theme.txModal.gap};
  background: ${({ theme }) => theme.colors.lightGray};
  box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
  text-transform: uppercase;
`;

export const TxContainer: FC<TxContainerProps> = ({ header, onClose, children, ...props }) => {
  let closeButton;

  if (onClose) {
    closeButton = (
      <HeaderAction onClick={onClose} data-testid="close-transaction-modal">
        <StyledIcon Component={CloseIcon} />
      </HeaderAction>
    );
  }

  return (
    <StyledTxContainer {...props}>
      {(header || closeButton) && (
        <TxHeader>
          {header && <TxHeaderTitle>{header}</TxHeaderTitle>}
          <TxHeaderActions>{closeButton}</TxHeaderActions>
        </TxHeader>
      )}

      <TxContainerContent>{children}</TxContainerContent>
    </StyledTxContainer>
  );
};
