import { FC, useState } from "react";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { device } from "@themes/default";
import { TokenIcon } from "@components/app";
import { useAppTranslation } from "@hooks";
import { Text, Icon, Button, SearchList, ZapIcon, SearchListItem } from "@components/common";
import { formatUsd, humanize } from "@utils";

const MaxButton = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.bgSky12};
  color: ${({ theme }) => theme.colors.sky};
  border-radius: ${({ theme }) => theme.xsRadius};
  transition: opacity ${({ theme }) => theme.transition.eo250};
  border: none;

  &:hover {
    opacity: ${({ theme }) => theme.opac64};
  }

  @media ${device.mobileXS} {
    display: none;
  }
`;

const StyledAmountInput = styled.input<{ readOnly?: boolean; error?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.6rem;
  width: 100%;
  text-align: right;
  background: transparent;
  outline: none;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  padding: 0;
  appearance: textfield;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.zinc500};
  }

  ${({ readOnly, theme }) =>
    readOnly &&
    `
    color: ${theme.colors.zinc400};
    cursor: default;

    &::placeholder {
      color: ${theme.colors.zinc500};
    }
  `}

  ${({ error, theme }) => error && `color: ${theme.colors.red};`}

  ${() => `
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    };
  `}
`;

const ContrastText = styled.span`
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.teal};
  font-size: 1.8rem;
`;

const StyledText = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.zinc200};

  &:last-child {
    width: max-content;
    left: 0;

    @media ${device.mobileS} {
      display: none;
    }
  }
`;

const StyledTextAlt = styled(Text)`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: ${({ theme }) => theme.colors.zinc200};

  &:last-child {
    width: max-content;
    right: 0;

    @media ${device.mobileS} {
      display: none;
    }
  }
`;

const TokenExtras = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.8rem;

  ${StyledText} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

// const AmountInputContainer = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   margin-top: 0.8rem;
// `;

const AmountTitle = styled(Text)`
  margin-top: 1.4rem;
  text-align: right;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const TokenData = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.darkGray};
  font-size: 1.4rem;
  flex: 1;
`;

const TokenName = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-size: 1.4rem;
`;

const TokenListIcon = styled(Icon)`
  display: none;
  position: absolute;
  top: 50%;
  left: 85%;
  transform: translate(-50%, -50%);
  fill: inherit;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const TokenIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ZapMessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.lgRadius};
  background: ${({ theme }) => theme.colors.lightGray};
  padding: ${({ theme }) => theme.mdLayoutPadding};
  font-size: 1.4rem;
  width: 100%;
`;

const TokenSelector = styled.div<{ onClick?: () => void; center?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 14.4rem;
  border-radius: ${({ theme }) => theme.mdRadius};
  background: ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.white};
  fill: ${({ theme }) => theme.colors.white};
  flex-shrink: 1;
  padding: 0.8rem;
  gap: 0.8rem;
  user-select: none;
  position: relative;
  // ${({ onClick }) => onClick && "cursor: pointer;"}
  cursor: default;
  margin-top: 2.6rem;

  @media ${device.mobile} {
    width: 9.6rem;
  }
`;

const TokenInfo = styled.div<{ center?: boolean }>`
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  gap: ${({ theme }) => theme.txModal.gap};
  overflow: hidden;
`;

const StyledSearchList = styled(SearchList)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform-origin: bottom left;
`;

const Header = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.zinc200};
  position: absolute;
`;

const HighlightText = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 0.5rem;
  cursor: pointer;
`;

const scaleTransitionTime = 300;

const StyledTxTokenInput = styled(TransitionGroup)`
  display: grid;
  background: ${({ theme }) => theme.colors.darkGray};
  min-height: 16rem;
  width: 100%;
  border-radius: ${({ theme }) => theme.xlRadius};
  padding: calc(${({ theme }) => theme.txModal.gap} * 1);
  grid-gap: 0.8rem;
  border: solid 0.1rem transparent;

  &:first-of-type {
    &:hover {
      border-color: ${({ theme }) => theme.colors.zinc600};
    }
  }

  .scale-enter {
    opacity: 0;
    transform: scale(0);
    transition: opacity ${({ theme }) => theme.transition.eo250}, transform ${({ theme }) => theme.transition.eo250};
  }

  .scale-enter-active {
    opacity: 1;
    transform: scale(1);
  }

  .scale-exit {
    opacity: 1;
    transform: scale(1);
  }

  .scale-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: opacity ${({ theme }) => theme.transition.eo250}, transform ${({ theme }) => theme.transition.eo250};
  }
`;

const amountToNumber = (amount: string) => {
  const parsedAmount = amount.replace(/[%,$]/g, "");
  return parseInt(parsedAmount);
};

interface Token {
  address: string;
  symbol: string;
  icon?: string;
  balance: string;
  balanceUsdc: string;
  decimals: number;
  yield?: string;
}

export interface TxTokenInputProps {
  headerText?: string;
  inputText?: string;
  inputError?: boolean;
  amount: string;
  onAmountChange?: (amount: string) => void;
  amountValue?: string;
  maxAmount?: string;
  maxLabel?: string;
  selectedToken: Token;
  onSelectedTokenChange?: (address: string) => void;
  yieldPercent?: string;
  tokenOptions?: Token[];
  readOnly?: boolean;
  hideAmount?: boolean;
  loading?: boolean;
  loadingText?: string;
  displayGuidance?: boolean;
}

export const TxTokenInput: FC<TxTokenInputProps> = ({
  headerText,
  inputText,
  inputError,
  amount,
  onAmountChange,
  amountValue,
  maxAmount,
  maxLabel = "MAX",
  selectedToken,
  onSelectedTokenChange,
  yieldPercent,
  tokenOptions,
  readOnly,
  hideAmount,
  loading,
  loadingText,
  displayGuidance,
  children,
  ...props
}) => {
  const { t } = useAppTranslation("common");

  let listItems: SearchListItem[] = [];
  let selectedItem: SearchListItem = {
    id: selectedToken.address,
    icon: selectedToken.icon,
    label: selectedToken.symbol,
    value: selectedToken.yield ?? humanize("usd", selectedToken.balanceUsdc),
  };

  if (tokenOptions && tokenOptions.length > 1) {
    listItems = tokenOptions
      .map((item) => {
        return {
          id: item.address,
          icon: item.icon,
          label: item.symbol,
          value: item.yield ?? humanize("usd", item.balanceUsdc),
        };
      })
      .sort((a, b) => amountToNumber(b.value) - amountToNumber(a.value));
    listItems.sort((a, b) => (a.id === selectedItem.id ? -1 : 1));
  }

  const openSearchList = () => {
    // setOpenedSearch(true);
    setOpenedSearch(false);
  };

  const [openedSearch, setOpenedSearch] = useState(false);
  const searchListHeader = selectedToken.yield
    ? t("components.transaction.token-input.search-select-vault")
    : t("components.transaction.token-input.search-select-token");

  return (
    <StyledTxTokenInput {...props}>
      <>{headerText && <Header>{headerText}</Header>}</>
      {openedSearch && (
        <CSSTransition in={openedSearch} appear={true} timeout={250} classNames="scale">
          <StyledSearchList
            list={listItems}
            headerText={searchListHeader}
            selected={selectedItem}
            setSelected={(item) => (onSelectedTokenChange ? onSelectedTokenChange(item.id) : undefined)}
            onCloseList={() => setOpenedSearch(false)}
          />
        </CSSTransition>
      )}

      {/* NOTE Using fragments here because: https://github.com/yearn/yearn-finance-v3/pull/565 */}
      <>
        <TokenInfo center={hideAmount}>
          <TokenSelector onClick={listItems?.length > 1 ? openSearchList : undefined} center={hideAmount}>
            <TokenIconContainer>
              <TokenIcon icon={selectedItem.icon} symbol={selectedItem.label} size="big" />
              {listItems?.length > 1 && <TokenListIcon Component={ZapIcon} />}
            </TokenIconContainer>
            <TokenName>{selectedItem.label}</TokenName>
          </TokenSelector>

          {!hideAmount && (
            <TokenData>
              <AmountTitle ellipsis>{inputText || t("components.transaction.token-input.you-have")}</AmountTitle>

              <StyledAmountInput
                value={amount}
                onChange={onAmountChange ? (e) => onAmountChange(e.target.value) : undefined}
                placeholder={loading ? loadingText : "0"}
                readOnly={readOnly}
                error={inputError}
                type="number"
                aria-label={headerText}
              />
              {maxAmount && (
                <MaxButton outline onClick={onAmountChange ? () => onAmountChange(maxAmount) : undefined}>
                  {maxLabel}
                </MaxButton>
              )}

              <TokenExtras>
                {amountValue && <StyledText>{formatUsd(!loading && !inputError ? amountValue : "0")}</StyledText>}
                {yieldPercent && (
                  <StyledTextAlt>
                    {t("components.transaction.token-input.yield")}
                    <ContrastText> {yieldPercent} </ContrastText>
                  </StyledTextAlt>
                )}
              </TokenExtras>
            </TokenData>
          )}
        </TokenInfo>
        {/* {listItems?.length > 1 && displayGuidance && (
          <ZapMessageContainer onClick={openSearchList}>
            ⚡ {t("components.transaction.zap-guidance.part-1")}{" "}
            <HighlightText> {t("components.transaction.zap-guidance.part-2")} </HighlightText>{" "}
            {t("components.transaction.zap-guidance.part-3")}
          </ZapMessageContainer>
        )} */}
      </>
    </StyledTxTokenInput>
  );
};
