import { SharedTheme } from "styled-components";

const sharedTheme: SharedTheme = {
  navbar: {
    height: "6.6rem",
  },
  zindex: {
    navbar: 400,
    sidemenu: 500,
    tooltips: 600,
    navSidemenu: 1000,
    modals: 1099,
    onboardModal: 1100,
    alerts: 1200,
  },

  devices: {
    mobileXS: 359,
    mobileS: 399,
    mobile: 479,
    mobileL: 599,
    tabletS: 799,
    tablet: 959,
    tabletL: 1023,
    desktopS: 1365,
    desktop: 1919,
    desktopL: 2559,
  },

  opac16: "0.16",
  opac24: "0.24",
  opac32: "0.32",
  opac48: "0.48",
  opac64: "0.64",
  opac80: "0.80",

  globalFont: "HMAlphaMono, monospace",
  globalMaxWidth: "1920px",

  transition: {
    cb200: "200ms cubic-bezier(0.72, 0.12, 0.09, 0.81) 0s",
    eo250: "250ms ease-out 0s",
  },

  sideBar: {
    // width: '16rem',
    width: "0",
    // collapsedWidth: '4.8rem',
    collapsedWidth: "0",
  },

  tabbar: {
    height: "6.4rem",
  },

  alerts: {
    default: {
      background: "linear-gradient(270deg, rgb(11,84,79) 1.04%, rgb(11,84,79) 98.99%) 25% 25% / 125% 125%",
      color: "white",
    },
    info: {
      background: "#17a2b8",
      color: "white",
    },
    success: {
      background: "#28a745",
      color: "white",
    },
    error: {
      background: "#dc3545",
      color: "white",
    },
    warning: {
      background: "#ffc107",
      color: "black",
    },
  },

  txModal: {
    width: "48rem",
    gap: "1.2rem",
  },

  xxsRadius: "0.50rem",
  xsRadius: "0.75rem",
  smRadius: "1.00rem",
  mdRadius: "1.25rem",
  lgRadius: "1.75rem",
  xlRadius: "2.00rem",
  xxlRadius: "2.75rem",

  xxsLayoutPadding: "0.50rem",
  xsLayoutPadding: "0.75rem",
  smLayoutPadding: "1.00rem",
  mdLayoutPadding: "1.25rem",
  lgLayoutPadding: "1.50rem",
  xlLayoutPadding: "1.75rem",
  xxlLayoutPadding: "3.00rem",
  xxxlLayoutPadding: "4.00rem",

  buttonPadding: "0.875rem",

  smBoxSize: "0.225rem",
  mdBoxSize: "0.275rem",
  lgBoxSize: "0.350rem",

  card: {
    // padding: "2.4rem",
    padding: "1.2rem",
    paddingVariant: "8rem",
  },
};

export const device = {
  mobileXS: `(max-width: ${sharedTheme.devices.mobileXS}px)`,
  mobileS: `(max-width: ${sharedTheme.devices.mobileS}px)`,
  mobile: `(max-width: ${sharedTheme.devices.mobile}px)`,
  mobileL: `(max-width: ${sharedTheme.devices.mobileL}px)`,
  tabletS: `(max-width: ${sharedTheme.devices.tabletS}px)`,
  tablet: `(max-width: ${sharedTheme.devices.tablet}px)`,
  tabletL: `(max-width: ${sharedTheme.devices.tabletL}px)`,
  desktopS: `(max-width: ${sharedTheme.devices.desktopS}px)`,
  desktop: `(max-width: ${sharedTheme.devices.desktop}px)`,
  desktopL: `(max-width: ${sharedTheme.devices.desktopL}px)`,
};

export { sharedTheme };
