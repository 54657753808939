import { ReactNode } from "react";
import styled from "styled-components";

import { Card, CardHeader, CardContent, CardSizeType } from "@components/common";
// import { HomeButtons } from "@components/app";
import { device } from "@themes/default";
// import { ReactComponent as LogoFull } from "@assets/images/asx-protocol-logo-full.svg";

const StyledCardHeaderWrapper = styled(CardHeader)`
  font-size: 6rem;

  @media ${device.tabletL} {
    // background: transparent;
    // -webkit-background-clip: border-box;
    font-size: 5.6rem;
  }

  @media ${device.tabletS} {
    font-size: 4.8rem;
  }

  @media ${device.mobileL} {
    font-size: 3.6rem;
  }

  @media ${device.mobile} {
    font-size: 3rem;
  }

  @media ${device.mobileS} {
    font-size: 2.2rem;
  }

  @media ${device.mobileXS} {
    font-size: 1.8rem;
  }
`;

const StyledCardSubHeader = styled(CardHeader)`
  background: hsla(24, 100%, 83%, 1);
  background: linear-gradient(180deg, hsla(24, 100%, 83%, 1) 0%, hsla(341, 91%, 68%, 1) 240%);
  background: -moz-linear-gradient(180deg, hsla(24, 100%, 83%, 1) 0%, hsla(341, 91%, 68%, 1) 240%);
  background: -webkit-linear-gradient(180deg, hsla(24, 100%, 83%, 1) 0%, hsla(341, 91%, 68%, 1) 240%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FFCAA6", endColorstr="#F86594", GradientType=1 );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  width: max-content;
  padding: 0;
`;

const StyledCardContent = styled(CardContent)`
  display: block;
  padding: calc(${({ theme }) => theme.card.padding} * 2.8) calc(${({ theme }) => theme.card.padding} * 1.3);
  color: ${({ theme }) => theme.colors.zinc200};
  text-align: left;
  font-size: 2.4rem;
  max-width: 50rem;

  @media ${device.tabletL} {
    padding: calc(${({ theme }) => theme.card.padding} * 2.4) calc(${({ theme }) => theme.card.padding} * 1.3);
    font-size: 2rem;
    max-width: 42rem;
  }

  @media ${device.tabletS} {
    padding: calc(${({ theme }) => theme.card.padding} * 2) calc(${({ theme }) => theme.card.padding} * 1.3);
    font-size: 1.8rem;
    max-width: 38rem;
  }

  @media ${device.mobileL} {
    font-size: 1.6rem;
    max-width: 34rem;
  }

  @media ${device.mobile} {
    padding: calc(${({ theme }) => theme.card.padding} * 1.8) calc(${({ theme }) => theme.card.padding} * 1.3);
    font-size: 1.4rem;
    max-width: 30rem;
  }

  @media ${device.mobileS} {
    font-size: 1.3rem;
    max-width: 28rem;
  }

  @media ${device.mobileXS} {
    padding: calc(${({ theme }) => theme.card.padding} * 1.6) calc(${({ theme }) => theme.card.padding} * 1.3);
    font-size: 1.2rem;
    max-width: 26rem;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${({ theme }) => theme.globalMaxWidth};
  text-align: left;
  color: ${({ theme }) => theme.colors.zinc200};

  @media ${device.desktop} {
    max-width: 90vw;
  }

  @media ${device.mobile} {
    max-width: 98vw;
  }
`;

interface InfoCardProps {
  header: string;
  subheader?: string;
  content?: string;
  Component?: ReactNode;
  variant?: "primary" | "secondary";
  cardSize?: CardSizeType;
}

export const HomeLayout = ({ header, subheader, content, Component, variant, cardSize, ...props }: InfoCardProps) => {
  return (
    <StyledCard variant={variant} cardSize={cardSize} {...props}>
      {/* <LogoFull /> */}

      <StyledCardHeaderWrapper>
        {header}
        <StyledCardSubHeader>{subheader}</StyledCardSubHeader>
      </StyledCardHeaderWrapper>

      <StyledCardContent>{content}</StyledCardContent>
      {Component}
    </StyledCard>
  );
};
