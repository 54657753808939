import { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  ActionButtons,
  Amount,
  ApyTooltipData,
  DetailCard,
  // InfoCard,
  // NoWalletCard,
  // RecommendationsCard,
  SummaryCard,
  TokenIcon,
  ViewContainer,
} from "@components/app";
// import { Button, SearchInput, SpinnerLoading, Text, Tooltip } from "@components/common";
import { Button, SearchInput, SpinnerLoading, Tooltip } from "@components/common";
import { getConfig } from "@config";
import { useAppDispatch, useAppSelector, useAppTranslation, useIsMounting } from "@hooks";
import { VaultView } from "@src/core/types";
import {
  AlertsActions,
  AppSelectors,
  ModalsActions,
  ModalSelectors,
  NetworkSelectors,
  TokensSelectors,
  VaultsActions,
  VaultsSelectors,
  WalletSelectors,
} from "@store";
import { device } from "@themes/default";
import { AlertTypes } from "@types";
import {
  formatApy,
  // halfWidthCss,
  humanize,
  isCustomApyType,
  normalizeAmount,
  orderApy,
  toBN,
  USDC_DECIMALS,
} from "@utils";

const SearchBarContainerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const SearchBarContainer = styled.div`
  height: max-content;
  min-width: 28rem;

  @media ${device.mobileS} {
    min-width: 84vw;
    margin-left: 0;
    margin-right: 0;
  }

  @media ${device.mobileXS} {
  }
`;

const Row = styled.div`
  // display: flex;
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  grid-gap: ${({ theme }) => theme.lgLayoutPadding};
  width: 100%;
`;

const StyledHelperCursor = styled.span`
  // cursor: help;
`;

// const StyledRecommendationsCard = styled(RecommendationsCard)``;

// const StyledInfoCard = styled(InfoCard)`
//   width: 100%;
// `;

// const StyledNoWalletCard = styled(NoWalletCard)`
//   width: 100%;
//   ${halfWidthCss}
// `;

const StyledSummaryCard = styled(SummaryCard)`
  margin: 0 auto;
`;

const StyledTabButtonWrapper = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: 2rem auto 1rem auto;
  box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
  border-radius: ${({ theme }) => theme.smRadius};
  background: ${(props) => props.theme.colors.darkGray};
`;

const StyledTabButton = styled.button`
  border: none;
  border-radius: ${({ theme }) => theme.smRadius};
  padding: ${({ theme }) => theme.buttonPadding};
  font-size: 1.6rem;
  cursor: pointer;

  color: ${(props) => (props.className === "active" ? props.theme.colors.white : props.theme.colors.zinc400)};
  background-color: ${(props) => (props.className === "active" ? props.theme.colors.lightGray : "transparent")};

  min-width: 14rem;

  @media ${device.mobileS} {
    min-width: 40vw;
  }
`;

const StyledWrapper = styled.div`
  padding: 0;
  margin: 0 auto;
`;

const OpportunitiesCard = styled(DetailCard)`
  display: grid;
  max-width: max-content;
  margin: 2.4rem auto 0 auto;
  padding: 0;
  text-transform: uppercase;

  & > div:nth-of-type(2) {
    /* display: none; */
  }

  & > * {
    min-width: 28rem;

    @media ${device.mobileS} {
      min-width: 84vw;
      margin-left: 0;
      margin-right: 0;
    }

    @media ${device.mobileXS} {
    }
  }

  [wrap="nowrap"] {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    padding: 0;
    width: 100%;
    max-width: 28rem;

    [width="auto"] {
      display: none;
    }

    .col-name,
    .col-banner,
    .col-icon,
    .col-apy-title,
    .col-assets-title,
    .col-balance,
    .col-earned {
      display: none;
    }
  }

  [data-testid="list-item"] {
    position: relative;
  }

  @media ${device.desktop} {
    grid-template-columns: repeat(4, 1fr);

    [data-testid="list-item"] {
      &:nth-of-type(-n + 5) {
        margin-top: 7.2rem;
      }
    }
  }

  @media ${device.desktopS} {
    grid-template-columns: repeat(3, 1fr);

    [data-testid="list-item"] {
      &:nth-of-type(-n + 5) {
        margin-top: 2rem;
      }
      &:nth-of-type(-n + 4) {
        margin-top: 7.2rem;
      }
    }
  }

  @media ${device.tabletL} {
    grid-template-columns: repeat(2, 1fr);

    [data-testid="list-item"] {
      &:nth-of-type(-n + 4) {
        margin-top: 2rem;
      }
      &:nth-of-type(-n + 3) {
        margin-top: 7.2rem;
      }
    }
  }

  @media ${device.tabletS} {
    grid-template-columns: 1fr;

    [data-testid="list-item"] {
      &:nth-of-type(-n + 3) {
        margin-top: 2rem;
      }
      &:nth-of-type(-n + 2) {
        margin-top: 7.2rem;
      }
    }
  }
` as typeof DetailCard;

const DepositsCard = styled(OpportunitiesCard)`
  [wrap="nowrap"] {
    /* display: none; */
  }
` as typeof OpportunitiesCard;

// const DeprecatedCard = styled(DetailCard)`` as typeof OpportunitiesCard;

const StyledButton = styled(Button)`
  display: none;
  position: absolute;
  right: 2.8rem;
  margin-top: 1.4rem;
  padding: ${({ theme }) => theme.smLayoutPadding};
  border-radius: ${({ theme }) => theme.xsRadius};
  background: ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.zinc200};

  @media ${device.tabletS} {
    margin-top: 1.2rem;
    font-size: 1rem;
  }

  @media ${device.mobileL} {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
`;

const StyledNoDeposits = styled.div`
  display: flex;
  justify-items: center;
  justify-content: center;
  margin: auto;
  padding: ${({ theme }) => theme.xxsLayoutPadding};
`;

const StyledNoDepositsButton = styled.button`
  cursor: pointer;
  margin: auto ${({ theme }) => theme.xxsLayoutPadding};
  border: none;
  color: ${({ theme }) => theme.colors.zinc200};
  background: ${({ theme }) => theme.colors.darkGray};
  padding: ${({ theme }) => theme.xxlLayoutPadding};
  border-radius: ${({ theme }) => theme.xlRadius};
  font-size: 1.8rem;

  @media ${device.mobile} {
    font-size: 1.6rem;
  }
  @media ${device.mobileS} {
    font-size: 1.5rem;
  }
  @media ${device.mobileXS} {
    font-size: 1.4rem;
  }
`;

const ApyTooltip = ({
  apyData,
  apyType,
  apyMetadata,
  address,
}: Pick<VaultView, "apyData" | "apyMetadata" | "address" | "apyType">) => {
  if (isCustomApyType(apyType) || !apyMetadata) {
    return <span>{formatApy(apyData, apyType)}</span>;
  }

  return (
    <Tooltip placement="bottom" tooltipComponent={<ApyTooltipData apy={apyMetadata} address={address} />}>
      <StyledHelperCursor>{formatApy(apyData, apyType)}</StyledHelperCursor>
    </Tooltip>
  );
};

export const Vaults = () => {
  const { t } = useAppTranslation(["common", "vaults"]);

  // const history = useHistory();
  const dispatch = useAppDispatch();
  const isMounting = useIsMounting();
  // const { isTablet, isMobile, width: DWidth } = useWindowDimensions();
  const { NETWORK_SETTINGS, CONTRACT_ADDRESSES } = getConfig();
  const walletIsConnected = useAppSelector(WalletSelectors.selectWalletIsConnected);
  const currentNetwork = useAppSelector(NetworkSelectors.selectCurrentNetwork);
  const currentNetworkSettings = NETWORK_SETTINGS[currentNetwork];
  // const { totalDeposits, totalEarnings, estYearlyYeild } = useAppSelector(VaultsSelectors.selectSummaryData);
  const { totalDeposits, totalEarnings } = useAppSelector(VaultsSelectors.selectSummaryData);
  // const recommendations = useAppSelector(VaultsSelectors.selectRecommendations);
  // const deprecated = useAppSelector(VaultsSelectors.selectDeprecatedVaults);
  const deposits = useAppSelector(VaultsSelectors.selectDepositedVaults);
  const opportunities = useAppSelector(VaultsSelectors.selectVaultsOpportunities);

  // const live = useAppSelector(VaultsSelectors.selectLiveVaults);

  const [filteredVaultsDep, setFilteredVaultsDep] = useState(deposits);
  const [filteredVaultsOpp, setFilteredVaultsOpp] = useState(opportunities);

  const activeModal = useAppSelector(ModalSelectors.selectActiveModal);

  const appStatus = useAppSelector(AppSelectors.selectAppStatus);
  const vaultsStatus = useAppSelector(VaultsSelectors.selectVaultsGeneralStatus);
  const tokensStatus = useAppSelector(TokensSelectors.selectWalletTokensStatus);
  const generalLoading =
    (appStatus.loading || vaultsStatus.loading || tokensStatus.loading || isMounting) && !activeModal;
  const opportunitiesLoading = generalLoading && !filteredVaultsOpp.length;
  const depositsLoading = generalLoading && !filteredVaultsDep.length;

  useEffect(() => {
    setFilteredVaultsDep(deposits);
  }, [deposits]);

  useEffect(() => {
    setFilteredVaultsOpp(opportunities);
  }, [opportunities]);

  const depositHandler = (vaultAddress: string) => {
    dispatch(VaultsActions.setSelectedVaultAddress({ vaultAddress }));
    dispatch(ModalsActions.openModal({ modalName: "depositTx" }));
  };

  const withdrawHandler = (vaultAddress: string) => {
    dispatch(VaultsActions.setSelectedVaultAddress({ vaultAddress }));
    dispatch(ModalsActions.openModal({ modalName: "withdrawTx" }));
  };

  // const migrateHandler = (vaultAddress: string) => {
  //   dispatch(VaultsActions.setSelectedVaultAddress({ vaultAddress }));
  //   dispatch(ModalsActions.openModal({ modalName: "migrateTx" }));
  // };

  const summaryCardItems = [
    { header: t("dashboard.holdings"), Component: <Amount value={totalDeposits} input="usdc" /> },
  ];

  if (currentNetworkSettings.earningsEnabled) {
    summaryCardItems.push(
      { header: t("dashboard.earnings"), Component: <Amount value={totalEarnings} input="usdc" /> }
      // { header: t('dashboard.est-yearly-yield'), Component: <Amount value={estYearlyYeild} input="usdc" /> }
    );
  }

  const openAlert = (message: string, type?: AlertTypes, persistent?: boolean) => {
    dispatch(AlertsActions.openAlert({ message, type, persistent }));
  };

  const [tabDeposit, setTabDeposit] = useState(true);
  const [tabWithdraw, setTabWithdraw] = useState(false);

  const depositTab = () => {
    setTabDeposit(true);
    setTabWithdraw(false);
  };

  const withdrawTab = () => {
    setTabDeposit(false);
    setTabWithdraw(true);
  };

  const filterVaults = (vault: VaultView) => {
    return (
      toBN(vault.apyMetadata?.net_apy).gt(0) ||
      isCustomApyType(vault.apyType) ||
      vault.address === CONTRACT_ADDRESSES.YVYFI
    );
  };

  return (
    <ViewContainer>
      <div id="background-radial-gradient"></div>

      <StyledButton
        onClick={() =>
          openAlert("THE PROTOCOL IS CURRENTLY IN PRIVATE BETA, ONLY WHITELISTED WALLETS CAN MAKE DEPOSITS")
        }
      >
        WHY CAN'T I MAKE A DEPOSIT?
      </StyledButton>

      <StyledSummaryCard header={t("dashboard.header")} items={summaryCardItems} variant="secondary" cardSize="small" />

      <StyledTabButtonWrapper>
        <StyledTabButton className={tabDeposit ? "active" : ""} onClick={() => depositTab()}>
          DEPOSIT
        </StyledTabButton>
        <StyledTabButton className={tabWithdraw ? "active" : ""} onClick={() => withdrawTab()}>
          WITHDRAW
        </StyledTabButton>
      </StyledTabButtonWrapper>

      {opportunitiesLoading && <SpinnerLoading flex="1" width="100%" />}

      {!opportunitiesLoading && (
        <>
          <Row>
            {/* <StyledRecommendationsCard
              header={t('components.recommendations.header')}
              items={recommendations.map(({ displayName, displayIcon, apyData, apyType, address }) => ({
                // header: 'Vault',
                icon: displayIcon,
                name: displayName,
                info: formatApy(apyData, apyType),
                infoDetail: 'EYY',
                onAction: () => history.push(`/vault/${address}`),
              }))}
            /> */}

            {/* <StyledInfoCard
              header={t('vaults:your-time-card.header')}
              Component={
                <Text>
                  <p>{t('vaults:your-time-card.desc-1')}</p>
                  <p>{t('vaults:your-time-card.desc-2')}</p>
                  <p>{t('vaults:your-time-card.desc-3')}</p>
                </Text>
              }
            /> */}
          </Row>

          {/* {!generalLoading && !walletIsConnected && <StyledNoWalletCard />} */}

          {/* <DeprecatedCard
            header={t('components.list-card.deprecated')}
            metadata={[
              {
                key: 'displayIcon',
                transform: ({ displayIcon, token }) => <TokenIcon icon={displayIcon} symbol={token.symbol} />,
                className: 'col-icon',
              },
              {
                key: 'displayName',
                header: t('components.list-card.asset'),
                transform: ({ displayIcon, displayName, token }) => (
                  <>
                    <TokenIcon icon={displayIcon} symbol={token.symbol} />
                    <Text ellipsis>{displayName}</Text>
                  </>
                ),
                width: '23rem',
                sortable: true,
                className: 'col-name',
              },

              {
                key: 'apy',
                header: 'APY',
                transform: ({ apyData, apyMetadata, apyType, address }) => (
                  <ApyTooltip apyType={apyType} apyData={apyData} apyMetadata={apyMetadata} address={address} />
                ),
                sortable: true,
                className: 'col-apy',
              },
              {
                key: 'balance',
                header: 'Balance',
                format: ({ userDeposited, token }) => humanize('amount', userDeposited, token.decimals, 4),
                sortable: true,
                className: 'col-balance',
              },
              {
                key: 'userDepositedUsdc',
                header: 'Value',
                format: ({ userDepositedUsdc }) => humanize('usd', userDepositedUsdc),
                sortable: true,
                className: 'col-value',
              },
              {
                key: 'earned',
                header: 'Earned',
                format: ({ earned }) => humanize('usd', earned),
                sortable: true,
                className: 'col-earned',
              },
              {
                key: 'actions',
                transform: ({ address, migrationAvailable }) => (
                  <ActionButtons
                    actions={[
                      { name: 'Migrate', handler: () => migrateHandler(address), disabled: !migrationAvailable },
                      { name: 'Withdraw', handler: () => withdrawHandler(address) },
                    ]}
                  />
                ),
                align: 'flex-end',
                width: 'auto',
                grow: '1',
              },
            ]}
            data={deprecated.map((vault) => ({
              ...vault,
              apy: orderApy(vault.apyData, vault.apyType),
              balance: normalizeAmount(vault.userDeposited, vault.token.decimals),
              actions: null,
            }))}
            onAction={({ address }) => history.push(`/vault/${address}`)}
            initialSortBy="userDepositedUsdc"
            wrap
          /> */}

          {tabWithdraw &&
            !depositsLoading &&
            (deposits.length === 0 ? (
              <StyledNoDeposits>
                <StyledNoDepositsButton onClick={() => depositTab()}>
                  YOU HAVEN'T DEPOSITED ANY ASSETS YET
                </StyledNoDepositsButton>
              </StyledNoDeposits>
            ) : (
              <StyledWrapper>
                <SearchBarContainerWrap>
                  <SearchBarContainer>
                    <SearchInput
                      searchableData={deposits}
                      searchableKeys={["name", "displayName", "token.symbol", "token.name"]}
                      placeholder=""
                      onSearch={(data) => setFilteredVaultsDep(data)}
                    />
                  </SearchBarContainer>
                </SearchBarContainerWrap>

                <DepositsCard
                  // header={t('components.list-card.deposits')}
                  header={t("")}
                  metadata={[
                    {
                      key: "banner",
                      title: "",
                      sortable: true,
                      className: "col-banner",
                    },
                    {
                      key: "displayIcon",
                      transform: ({ displayIcon, token }) => (
                        <TokenIcon icon={displayIcon} symbol={token.symbol} size="big" />
                      ),
                      className: "col-icon",
                    },
                    {
                      key: "displayName",
                      header: t("components.list-card.name"),
                      sortable: true,
                      className: "col-name",
                    },
                    {
                      key: "protocol",
                      title: "YEARN",
                      sortable: true,
                      width: "auto",
                      className: "col-protocol",
                    },
                    {
                      key: "apyTitle",
                      title: "APY",
                      sortable: true,
                      className: "col-apy-title",
                    },
                    {
                      key: "apy",
                      header: t("components.list-card.apy"),
                      transform: ({ apyData, apyMetadata, apyType, address }) => (
                        <ApyTooltip apyType={apyType} apyData={apyData} apyMetadata={apyMetadata} address={address} />
                      ),
                      sortable: true,
                      className: "col-apy",
                    },
                    {
                      key: "balTitle",
                      title: "BAL",
                      sortable: true,
                      className: "col-assets-title",
                    },
                    // {
                    //   key: 'balance',
                    //   header: t('components.list-card.balance'),
                    //   format: ({ userDeposited, token }) => humanize('amount', userDeposited, token.decimals, 4),
                    //   sortable: true,
                    //   className: 'col-balance',
                    // },
                    {
                      key: "userDepositedUsdc",
                      header: t("components.list-card.value"),
                      format: ({ userDepositedUsdc }) => humanize("usd", userDepositedUsdc),
                      sortable: true,
                      // className: 'col-value',
                      className: "col-assets",
                    },
                    // {
                    //   key: 'earned',
                    //   header: t('components.list-card.earned'),
                    //   format: ({ earned }) => (!toBN(earned).isZero() ? humanize('usd', earned) : '-'),
                    //   sortable: true,
                    //   className: 'col-earned',
                    // },
                    {
                      key: "actions",
                      transform: ({ address }) => (
                        <ActionButtons
                          actions={[
                            // { name: t('components.transaction.deposit'), handler: () => depositHandler(address) },
                            { name: t("components.transaction.withdraw"), handler: () => withdrawHandler(address) },
                          ]}
                        />
                      ),
                      align: "flex-end",
                      // align: 'center',
                      width: "auto",
                      grow: "1",
                    },
                  ]}
                  data={filteredVaultsDep.map((vault) => ({
                    ...vault,
                    apy: orderApy(vault.apyData, vault.apyType),
                    balance: normalizeAmount(vault.userDeposited, vault.token.decimals),
                    banner: null,
                    protocol: null,
                    apyTitle: null,
                    balTitle: null,
                    actions: null,
                  }))}
                  // SearchBar={}
                  searching={deposits.length > filteredVaultsDep.length}
                  // onAction={({ address }) => history.push(`/vault/${address}`)}
                  onAction={({ address }) => withdrawHandler(address)}
                  initialSortBy="userDepositedUsdc"
                  // initialSortBy="vaultBalanceUsdc"
                  // initialSortBy="apy"
                  wrap
                />
              </StyledWrapper>
            ))}

          {tabDeposit && !opportunitiesLoading && (
            <StyledWrapper>
              <SearchBarContainerWrap>
                <SearchBarContainer>
                  <SearchInput
                    searchableData={opportunities}
                    searchableKeys={["name", "displayName", "token.symbol", "token.name"]}
                    placeholder=""
                    onSearch={(data) => setFilteredVaultsOpp(data)}
                  />
                </SearchBarContainer>
              </SearchBarContainerWrap>

              <OpportunitiesCard
                header={t("components.list-card.opportunities")}
                data-testid="vaults-opportunities-list"
                metadata={[
                  {
                    key: "banner",
                    title: "",
                    sortable: true,
                    className: "col-banner",
                  },
                  {
                    key: "displayIcon",
                    transform: ({ displayIcon, token }) => (
                      <TokenIcon icon={displayIcon} symbol={token.symbol} size="big" />
                    ),
                    className: "col-icon",
                  },
                  {
                    key: "displayName",
                    header: t("components.list-card.name"),
                    sortable: true,
                    className: "col-name",
                  },
                  {
                    key: "protocol",
                    title: "YEARN",
                    sortable: true,
                    width: "auto",
                    className: "col-protocol",
                  },
                  {
                    key: "apyTitle",
                    title: "APY",
                    sortable: true,
                    className: "col-apy-title",
                  },
                  {
                    key: "apy",
                    header: t("components.list-card.apy"),
                    transform: ({ apyData, apyMetadata, apyType, address }) => (
                      <ApyTooltip apyType={apyType} apyData={apyData} apyMetadata={apyMetadata} address={address} />
                    ),
                    sortable: true,
                    className: "col-apy",
                  },
                  {
                    key: "tvlTitle",
                    title: "TVL",
                    sortable: true,
                    className: "col-assets-title",
                  },
                  {
                    key: "vaultBalanceUsdc",
                    header: t("components.list-card.total-assets"),
                    format: ({ vaultBalanceUsdc }) => humanize("usd", vaultBalanceUsdc, USDC_DECIMALS, 0),
                    sortable: true,
                    className: "col-assets",
                  },
                  {
                    key: "actions",
                    transform: ({ address }) => (
                      <ActionButtons
                        actions={[
                          {
                            name: t("components.transaction.deposit"),
                            handler: () => depositHandler(address),
                            disabled: !walletIsConnected,
                          },
                        ]}
                      />
                    ),
                    align: "flex-end",
                    // align: 'center',
                    width: "auto",
                    grow: "1",
                  },
                ]}
                data={filteredVaultsOpp.map((vault) => ({
                  ...vault,
                  apy: orderApy(vault.apyData, vault.apyType),
                  userTokenBalance: normalizeAmount(vault.token.balance, vault.token.decimals),
                  userTokenBalanceUsdc: vault.token.balanceUsdc,
                  banner: null,
                  protocol: null,
                  apyTitle: null,
                  tvlTitle: null,
                  actions: null,
                }))}
                // SearchBar={}
                searching={opportunities.length > filteredVaultsOpp.length}
                // onAction={({ address }) => history.push(`/vault/${address}`)}
                onAction={({ address }) => depositHandler(address)}
                // initialSortBy="userDepositedUsdc"
                initialSortBy="vaultBalanceUsdc"
                // initialSortBy="apy"
                wrap
              />
            </StyledWrapper>
          )}
        </>
      )}
    </ViewContainer>
  );
};
