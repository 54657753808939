import { FC } from "react";
import styled from "styled-components";

export interface SeparatorLineProps {
  className?: string;
  color?: string;
}

const StyledSeparatorLine = styled.div`
  height: 0.1rem;
  width: 100%;
  margin: 1rem 0;
  background: ${({ theme }) => theme.colors.darkGray};

  &.vertical {
    height: 100%;
    width: 0.1rem;
    margin: 0 1rem;
    background: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const SeparatorLine: FC<SeparatorLineProps> = ({ className, color, ...props }) => (
  <StyledSeparatorLine className={className} color={color} {...props} />
);
