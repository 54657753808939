import { DefaultTheme } from "styled-components";

import { Theme } from "@types";

import { darkTheme } from "./dark";
// import { lightTheme } from './light';

export const getTheme = (theme?: Theme): DefaultTheme => {
  switch (theme) {
    // case 'light':
    //   return lightTheme;
    case "dark":
      return darkTheme;
    // case "cyberpunk":
    //   return cyberpunkTheme;
    // case "classic":
    //   return classicTheme;
    // case "explorer":
    //   return explorerTheme;
    // case "system-prefs":
    //   const { matches: prefersColorSchemeDark } = window?.matchMedia("(prefers-color-scheme: dark)");
    //   return prefersColorSchemeDark ? darkTheme : lightTheme;
    default:
      return darkTheme;
  }
};
