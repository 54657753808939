import { FC } from "react";
import styled from "styled-components";

import { useAppTranslation } from "@hooks";
import { Text, ConstructionIcon, Icon, Modal, Button } from "@components/common";

const StyledButton = styled(Button)`
  background: ${({ theme }) => theme.colors.gold};
`;

const StyledIcon = styled(Icon)`
  fill: currentColor;
  width: 9.4rem;
`;

const StyledText = styled(Text)`
  font-size: 2.4rem;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  padding: 9.6rem 4.8rem;
  background: ${({ theme }) => theme.colors.darkGray};
  border-radius: ${({ theme }) => theme.smRadius};
`;

const StyledComingSoonModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.txModal.gap};

  width: ${({ theme }) => theme.txModal.width};
  height: auto;
  color: ${({ theme }) => theme.colors.gold};
  padding: ${({ theme }) => theme.txModal.gap};
  border-radius: ${({ theme }) => theme.smRadius};
`;

export interface ComingSoonModalProps {
  onClose: () => void;
  modalProps: {
    testVar: string;
  };
}

export const ComingSoonModal: FC<ComingSoonModalProps> = ({ onClose, modalProps, ...props }) => {
  const { t } = useAppTranslation("modals");

  return (
    <StyledComingSoonModal {...props}>
      <TextContainer>
        <StyledText>{t("modals:coming-soon.text")}</StyledText>
        <StyledIcon Component={ConstructionIcon} />
      </TextContainer>

      <StyledButton onClick={onClose}>{t("modals:exit")}</StyledButton>
    </StyledComingSoonModal>
  );
};
