import { FC, ReactNode } from "react";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import styled from "styled-components";

import { ReactComponent as INFO } from "@assets/icons/info-dark-filled.svg";
import { ReactComponent as ASX } from "@assets/images/asx-protocol-logo.svg";
import { ReactComponent as CVX } from "@assets/images/cvx.svg";
import { ReactComponent as TAROT } from "@assets/images/tarot.svg";
import { ReactComponent as YFI } from "@assets/images/yfi.svg";
import { Card, CardContent, CardElement, CardHeader, CardRedirection, SeparatorLine, Text } from "@components/common";
import { useAppSelector } from "@hooks";
import { NetworkSelectors, VaultsSelectors } from "@store";
import { device } from "@themes/default";

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  flex-shrink: 1;
  margin: ${({ theme }) => theme.xxlLayoutPadding} auto ${({ theme }) => theme.lgLayoutPadding} auto;
  border-radius: ${({ theme }) => theme.lgRadius};
  padding: ${({ theme }) => theme.lgLayoutPadding};

  @media ${device.mobileS} {
    max-width: 24rem;
  }

  div[role="menu"] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 47.5%);
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    width: 100%;
    min-width: 42rem;
    background: ${({ theme }) => theme.colors.lightGray};
    padding: ${({ theme }) => theme.lgLayoutPadding};
    padding-top: ${({ theme }) => theme.xsLayoutPadding};
    border-radius: ${({ theme }) => theme.lgRadius};
    box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
    font-size: 1.4rem;
    z-index: 50;

    @media ${device.mobileL} {
      display: none;
    }
  }

  .details-button {
    position: absolute;
    top: 67.5%;
    left: 50%;
    transform: translate(-50%, 67.5%);
    background: transparent;
    border: none;
    cursor: pointer;

    @media ${device.mobileL} {
      display: none;
    }
  }

  div[role="menu"] a {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  div[role="menu"] span {
    display: flex;
    height: max-content;
    width: 12rem;
    margin: 0.4rem;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
  }

  div[role="menu"].visible {
    visibility: visible;
  }
`;

const StyledSeparatorLine = styled(SeparatorLine)`
  margin: 0.4rem;
  opacity: 0;
`;

const StyledSpan = styled.span`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.zinc400};
  box-shadow: 0 0.1rem 0 0 ${({ theme }) => theme.colors.zinc600};
  padding: ${({ theme }) => theme.xsLayoutPadding};
  border-radius: ${({ theme }) => theme.xsRadius};
`;

const StyledINFO = styled(INFO)`
  height: 2.4rem;
  width: 2.4rem;
`;

const StyledASX = styled(ASX)`
  height: 2rem;
  width: 2rem;
`;

const StyledCVX = styled(CVX)`
  height: 2rem;
  width: 2rem;
`;

const StyledYFI = styled(YFI)`
  height: 2rem;
  width: 2rem;
`;

const StyledTAROT = styled(TAROT)`
  height: 2rem;
  width: 2rem;
`;

const StyledCardContent = styled(CardContent)<{ withArrow?: boolean }>`
  ${({ withArrow }) => withArrow && `margin-right: 4.8rem`};
`;

const StyledCardElement = styled(CardElement)`
  overflow: hidden;
  flex: 1;
  min-width: max-content;
  margin: 0 auto;
  padding: ${({ theme }) => theme.lgLayoutPadding} ${({ theme }) => theme.xxlLayoutPadding} !important;
  /* color: #ffb8a4; */
  color: ${({ theme }) => theme.colors.zinc400};

  h3 {
    font-size: 1.6rem;
    margin: 0 auto;
  }
`;

const StyledText = styled(Text)<{ variant?: string }>`
  -webkit-text-fill-color: ${({ theme, variant }) =>
    variant === "secondary" ? theme.colors.white : theme.colors.zinc200};
`;

interface Item {
  header: string;
  content?: string;
  Component?: ReactNode;
}

interface SummaryCardProps {
  header?: string;
  items: Item[];
  variant?: "primary" | "secondary";
  cardSize?: "small" | "big";
  redirectTo?: string;
}

export const SummaryCard: FC<SummaryCardProps> = ({ header, items, variant, cardSize, redirectTo, ...props }) => {
  const currentNetwork = useAppSelector(NetworkSelectors.selectCurrentNetwork);
  const { totalDeposits, totalEarnings } = useAppSelector(VaultsSelectors.selectSummaryData);
  const { buttonProps, itemProps, isOpen } = useDropdownMenu(4);

  const balanceNum = totalDeposits;
  const earnedNum = totalEarnings;

  const holdings =
    Number(balanceNum) > 999.99 ? `$${Number(balanceNum).toLocaleString()}` : `$${Number(balanceNum).toFixed(2)}`;

  const earnings =
    Number(earnedNum) / 1000000 > 999.99
      ? `$${(Number(earnedNum) / 1000000).toLocaleString()}`
      : `$${(Number(earnedNum) / 1000000).toFixed(2)}`;

  return (
    <StyledCard variant={variant} cardSize={cardSize} {...props}>
      {header && <CardHeader header={header} />}
      {redirectTo && <CardRedirection redirectTo={redirectTo} />}

      <StyledCardContent wrap>
        {items.map((item) => (
          <StyledCardElement
            key={item.header}
            header={item.header}
            content={<StyledText variant={variant}>{item.content}</StyledText>}
          >
            {item.Component && <StyledText variant={variant}>{item.Component}</StyledText>}
          </StyledCardElement>
        ))}
        <button className="details-button" {...buttonProps}>
          <StyledINFO />
        </button>

        <div className={isOpen ? "visible" : ""} role="menu">
          <a {...itemProps[0]}>
            <StyledSpan>BALANCE</StyledSpan>
            <StyledSpan>EARNED</StyledSpan>
            <StyledSpan>PROTOCOL</StyledSpan>
          </a>

          <StyledSeparatorLine />

          <a {...itemProps[1]}>
            <span>$0.00</span>
            <span>$0.00</span>
            <span>
                 ASX 
              <StyledASX />
            </span>
          </a>

          {currentNetwork === "mainnet" && (
            <>
              <a {...itemProps[2]}>
                <span>$0.00</span>
                <span>$0.00</span>
                <span>
                  CONVEX 
                  <StyledCVX />
                </span>
              </a>
            </>
          )}
          <a {...itemProps[3]}>
            <span>{holdings}</span>
            <span>{earnings}</span>
            <span>
               YEARN 
              <StyledYFI />
            </span>
          </a>

          {currentNetwork === "fantom" && (
            <>
              <a {...itemProps[2]}>
                <span>$0.00</span>
                <span>$0.00</span>
                <span>
                   TAROT 
                  <StyledTAROT />
                </span>
              </a>
            </>
          )}
        </div>
      </StyledCardContent>
    </StyledCard>
  );
};
