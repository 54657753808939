import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { ConnectWalletButton } from "@components/app";
import { OptionList, EthereumIcon, FantomIcon, ArbitrumIcon } from "@components/common";
// import { useWindowDimensions } from "@hooks";
import { Network } from "@types";
import { device } from "@themes/default";
// import { getConfig } from "@config";
import { isInIframe } from "@utils";
import { Logo } from "@components/common";

const StyledLogo = styled(Logo)`
  justify-content: flex-start;
  height: 4rem;
  padding: 1.2rem 0;
  fill: ${({ theme }) => theme.colors.logo};
  cursor: pointer;

  @media ${device.mobileS} {
    height: 3.8rem;
  }

  @media ${device.mobileXS} {
    height: 3.4rem;
  }
`;

const StyledOptionList = styled(OptionList)``;

const StyledText = styled.h1`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.zinc200};
  margin: 0;
  padding: 0;
`;

const StyledNavbarActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.8rem;
  padding-left: 0.8rem;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  > * {
    height: max-content;
  }
`;

const StyledNavbar = styled.header`
  /* position: sticky; */
  /* border-bottom: solid 0.1rem ${({ theme }) => theme.colors.bgDark16}; */
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: ${(props) => props.theme.zindex.navbar};
  max-width: ${({ theme }) => theme.globalMaxWidth};
  margin-top: -${({ theme }) => theme.lgLayoutPadding};
  padding: ${({ theme }) => theme.lgLayoutPadding} 1.2rem 1.4rem 1.2rem;

  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    /* background: ${({ theme }) => theme.colors.lightGray64}; */
  }

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    /* background: ${({ theme }) => theme.colors.lightGray08}; */
    /* -webkit-backdrop-filter: blur(4rem); */
    /* backdrop-filter: blur(4rem); */
  }

  @media ${device.mobileL} {
    ${StyledText} {
      font-size: 1.8rem;
    }
    ${StyledOptionList} {
      width: auto;
    }
  }
`;

const getNetworkIcon = (network: Network) => {
  switch (network) {
    case "mainnet":
      return EthereumIcon;
    case "fantom":
      return FantomIcon;
    case "arbitrum":
      return ArbitrumIcon;
    default:
      return;
  }
};

interface NavbarProps {
  className?: string;
  title?: string;
  titleLink?: string;
  subTitle?: string;
  walletAddress?: string;
  addressEnsName?: string;
  onWalletClick?: () => void;
  disableWalletSelect?: boolean;
  selectedNetwork: Network;
  networkOptions: Network[];
  onNetworkChange: (network: string) => void;
  disableNetworkChange?: boolean;
  hideDisabledControls?: boolean;
}

export const Navbar = ({
  className,
  title,
  titleLink,
  subTitle,
  walletAddress,
  addressEnsName,
  onWalletClick,
  disableWalletSelect,
  selectedNetwork,
  networkOptions,
  onNetworkChange,
  disableNetworkChange,
  hideDisabledControls,
}: NavbarProps) => {
  // const { isMobile } = useWindowDimensions();
  // const { NETWORK_SETTINGS } = getConfig();
  const isIframe = isInIframe();
  const history = useHistory();

  const dropdownSelectedNetwork = {
    value: selectedNetwork,
    // label: NETWORK_SETTINGS[selectedNetwork].name,
    label: "",
    Icon: getNetworkIcon(selectedNetwork),
  };
  const dropdownNetworkOptions = networkOptions.map((network, i) => ({
    value: network,
    // label: NETWORK_SETTINGS[network].name,
    label: "",
    Icon: getNetworkIcon(network),
  }));

  // const secondTitleEnabled = !!subTitle?.length;

  // const vaultText = (
  //   <>
  //     &nbsp;/&nbsp;<StyledText>{subTitle}</StyledText>
  //   </>
  // );

  return (
    <StyledNavbar className={className}>
      <StyledLogo full={true} onClick={() => history.push("/")} />
      {title && <StyledText>{title}</StyledText>}

      <StyledNavbarActions>
        <StyledOptionList
          selected={dropdownSelectedNetwork}
          setSelected={(option) => onNetworkChange(option.value)}
          options={dropdownNetworkOptions}
          // hideIcons={isMobile}
          isLoading={disableNetworkChange}
          disabled={disableNetworkChange || isIframe}
        />

        <ConnectWalletButton
          address={walletAddress}
          ensName={addressEnsName}
          onClick={() => onWalletClick && onWalletClick()}
          disabled={disableWalletSelect}
        />
      </StyledNavbarActions>
    </StyledNavbar>
  );
};
