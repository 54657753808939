import { FC } from "react";
import styled from "styled-components";

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  color?: string;
  outline?: boolean;
  onClick?: (e?: any) => void;
}

const StyledButton = styled.button<{ outline?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.8rem;
  height: 2.8rem;
  border: solid 0.2rem transparent;
  border-radius: ${({ theme }) => theme.smRadius};
  background: ${(props) => props.theme.colors.lightGray};
  color: ${(props) => props.theme.colors.darkGray};
  cursor: pointer;
  user-select: none;
  font-size: 1.4rem;
  transition: filter ${({ theme }) => theme.transition.eo250};

  a,
  span,
  div {
    color: inherit;
  }

  &:hover {
    // filter: contrast(90%);
  }

  ${(props) =>
    props.outline &&
    `
    border-color: ${props.color || props.theme.colors.bgDark};
    background: transparent;
    color: ${props.color || props.theme.colors.bgDark};
  `}
`;

export const Button: FC<ButtonProps> = ({ className, disabled, color, outline, onClick, children, ...props }) => (
  <StyledButton className={className} disabled={disabled} color={color} outline={outline} onClick={onClick} {...props}>
    {children}
  </StyledButton>
);
