import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

// import { Card, CardContent, CardElement, CardEmptyList, CardHeader, ToggleButton } from "@components/common";
import { Card, CardContent, CardElement, CardHeader, ToggleButton } from "@components/common";
import { device } from "@themes/default";
import { sort } from "@utils";

const StyledCardElement = styled(CardElement)<{ stripes?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  // NOTE Card element uses card padding and layout padding, also other card child components too, doing this
  // all the card components will work fine when modifying either of the paddings, since the paddings are
  // related between them
  padding: calc(${({ theme }) => theme.card.padding} / 4) calc(${({ theme }) => theme.lgLayoutPadding} / 2);
  font-size: 1.4rem;
  flex-shrink: 1;

  &:first-child {
    /* padding-left: ${({ theme }) => theme.card.padding}; */
  }
  &:last-child {
    /* padding-right: ${({ theme }) => theme.card.padding}; */
  }

  > * {
    margin-top: 0;
    font-size: inherit;
    color: inherit;
  }

  ${({ stripes, theme }) =>
    stripes &&
    `
    &:nth-child(even) {
      background: ${theme.colors.lightGray};
    }
  `}
`;

const TitleCardElement = styled(CardElement)`
  // display: none;
  display: flex;
  flex-shrink: 0;
  user-select: none;
  max-width: max-content;
  padding: ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.mdLayoutPadding};
  margin: 0 ${({ theme }) => theme.xxsLayoutPadding};
  border-radius: ${({ theme }) => theme.xsRadius};
  background: ${({ theme }) => theme.colors.darkGray};
  transition: opacity ${({ theme }) => theme.transition.eo250};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;

  &:hover {
    opacity: ${({ theme }) => theme.opac64};
  }

  h3 {
    font-size: 1.4rem;
  }
`;

const StyledCardContent = styled(CardContent)<{ wrap?: boolean; pointer?: boolean }>`
  display: grid;
  // grid-template-columns: 6rem 16.8rem 16.8rem 16.8rem 16.8rem 1fr;
  grid-template-columns: 1fr;
  align-items: stretch;
  justify-content: stretch;
  margin: 2rem;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.lgRadius};
  background: ${(props) => props.theme.colors.darkGray};
  ${({ pointer }) => pointer && `cursor: pointer;`};
  ${({ wrap }) => wrap && `flex-wrap: wrap;`};
  box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
  transition: box-shadow ${({ theme }) => theme.transition.eo250};

  .col-banner {
    width: 100%;
    height: 9.6rem;
    background: ${({ theme }) => theme.colors.lightGray};
  }

  .col-icon {
    margin: -3.6rem auto 6rem auto;
    width: max-content;
    padding: ${({ theme }) => theme.mdLayoutPadding};
    border-radius: 100%;
    background: ${(props) => props.theme.colors.lightGray};
  }

  .col-name,
  .col-protocol {
    position: absolute;
    width: max-content;
    color: ${({ theme }) => theme.colors.white};
  }

  .col-name {
    top: 1.4rem;
    left: 1.4rem;

    background: ${({ theme }) => theme.colors.darkGray};

    border-radius: ${({ theme }) => theme.xsRadius};
    padding: ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.mdLayoutPadding};
    font-size: 1.6rem;
  }

  .col-protocol {
    top: 1.875rem;
    right: 1.4rem;

    color: ${({ theme }) => theme.colors.zinc400};
    border-radius: ${({ theme }) => theme.xxsRadius};
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.xsLayoutPadding};
    /* box-shadow: 0 0.1rem 0 0 ${({ theme }) => theme.colors.zinc600}; */
    font-size: 1.2rem;
  }

  .col-available {
    display: none;
  }

  .col-assets-title {
    position: absolute;
    bottom: 8rem;
    left: 0.8rem;
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.smLayoutPadding}
      ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.smLayoutPadding};
    width: max-content;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
  }

  .col-assets {
    position: absolute;
    bottom: 4rem;
    left: 0.8rem;
    // margin: 0 auto 1.6rem 0.8rem;
    margin-bottom: 1.6rem;
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.xsLayoutPadding}
      ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.xsLayoutPadding};
    width: max-content;
    color: ${({ theme }) => theme.colors.cyan};
    font-size: 1.6rem;
  }

  .col-apy-title {
    position: absolute;
    bottom: 8rem;
    right: 0.8rem;
    // margin: 0 0.8rem 0 auto;
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.smLayoutPadding}
      ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.smLayoutPadding};
    width: max-content;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.2rem;
  }

  .col-apy {
    position: absolute;
    bottom: 4rem;
    right: 0.8rem;
    // margin: 0 0.8rem 1.6rem auto;
    margin-bottom: 1.6rem;
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.xsLayoutPadding}
      ${({ theme }) => theme.xsLayoutPadding} ${({ theme }) => theme.xsLayoutPadding};
    width: max-content;
    color: ${({ theme }) => theme.colors.teal};
    font-size: 1.6rem;
  }

  @media ${device.mobileXS} {
    .col-assets,
    .col-apy {
      font-size: 1.4rem;
    }
  }

  .action-button {
    width: 100%;
    background-color: ${(props) => props.theme.colors.bgSky12};
    color: ${(props) => props.theme.colors.sky};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: background-color ${({ theme }) => theme.transition.eo250};

    &[disabled],
    &.disabled {
    }
  }

  &:hover {
    box-shadow: 0 0 0 ${({ theme }) => theme.smBoxSize} ${(props) => props.theme.colors.darkGray};

    .action-button {
      background-color: ${(props) => props.theme.colors.bgSky04};
    }

    .col-name {
    }

    .col-icon {
    }
  }
`;

const StyledCardHeader = styled(CardHeader)`
  // display: flex;
  display: none;
  flex-wrap: center;
  justify-content: center;
  margin-bottom: 0.6rem;
`;

const StyledCard = styled(Card)`
  padding: ${({ theme }) => theme.card.padding} 0;
  width: 100%;
`;

const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.2rem;
  align-items: center;
`;

interface Metadata<T> {
  key: Extract<keyof T, string>;
  header?: string;
  align?: "flex-start" | "center" | "flex-end";
  fontWeight?: number;
  width?: string;
  grow?: "1" | "0";
  hide?: boolean;
  className?: string;
  sortable?: boolean;
  format?: (item: T) => string;
  transform?: (item: T) => ReactNode;
  title?: string;
}

interface DetailCardProps<T> {
  header: string;
  metadata: Metadata<T>[];
  data: T[];
  stripes?: boolean;
  wrap?: boolean;
  initialSortBy?: Extract<keyof T, string>;
  SearchBar?: ReactNode;
  searching?: boolean;
  onAction?: (item: T) => void;
  filterBy?: (item: T) => boolean;
  filterLabel?: string;
}

export const DetailCard = <T,>({
  header,
  metadata,
  data,
  stripes,
  wrap,
  initialSortBy,
  SearchBar,
  searching,
  onAction,
  filterBy,
  filterLabel,
  ...props
}: DetailCardProps<T>) => {
  const [sortedData, setSortedData] = useState(initialSortBy ? sort(data, initialSortBy) : data);
  const [sortedBy, setSortedBy] = useState(initialSortBy);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [filterToggle, setFilterToggle] = useState(filterBy ? false : true);
  const filteredData = filterBy ? sortedData.filter(filterBy) : sortedData;
  const displayData = filterToggle ? sortedData : filteredData;

  const handleSort = (key: Extract<keyof T, string>) => {
    if (sortedBy === key) {
      setSortedData([...sortedData].reverse());
      setOrder(order === "desc" ? "asc" : "desc");
    } else {
      setSortedData(sort(sortedData, key));
      setSortedBy(key);
      setOrder("desc");
    }
  };

  useEffect(() => {
    setSortedData(sortedBy ? sort(data, sortedBy, order) : data);
  }, [data]);

  if (data.length === 0 && !SearchBar) {
    return null;
  }

  return (
    <StyledCard {...props}>
      <StyledCardHeader header={header}>
        <SectionContent>
          {!!filterBy && (
            <>
              {filterLabel}
              <ToggleButton
                ariaLabel={filterLabel}
                selected={filterToggle}
                setSelected={setFilterToggle}
                data-testid="filter-toggle"
                data-active={filterToggle}
              />
            </>
          )}
          {SearchBar}
        </SectionContent>
      </StyledCardHeader>

      {!!displayData.length && (
        <CardContent>
          {metadata.map(
            ({ key, sortable, hide, className, transform, format, ...rest }) =>
              !hide && (
                <TitleCardElement
                  className={className}
                  key={key}
                  onClick={() => (sortable ? handleSort(key) : undefined)}
                  sortable={sortable}
                  activeSort={sortedBy === key}
                  sortType={order}
                  {...rest}
                />
              )
          )}
        </CardContent>
      )}

      {displayData.map((item, i) => (
        <StyledCardContent
          key={`content-${i}`}
          wrap={wrap}
          pointer={!!onAction}
          data-testid="list-item"
          onClick={() => {
            if (onAction) onAction(item);
          }}
        >
          {metadata.map(
            ({ key, width, align, grow, hide, fontWeight, className, format, transform, title }) =>
              !hide && (
                <StyledCardElement
                  key={`element-${key}-${i}`}
                  content={transform ? undefined : format ? format(item) : item[key]}
                  // fontWeight={fontWeight}
                  width={width}
                  align={align}
                  grow={grow}
                  stripes={stripes}
                  className={className}
                  header={title}
                >
                  {transform && transform(item)}
                </StyledCardElement>
              )
          )}
        </StyledCardContent>
      ))}

      {/* {!displayData.length && <CardEmptyList searching={searching} />} */}
    </StyledCard>
  );
};
