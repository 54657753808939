import { useState, useEffect, ImgHTMLAttributes } from "react";
import styled from "styled-components";

const StyledImg = styled.img<{ loaded?: boolean }>`
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
  transition: opacity ${({ theme }) => theme.transition.eo250};
`;

interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {}

export const Img = ({ alt, src, ...props }: ImgProps) => {
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    const image = new Image();
    image.src = src ?? "";
    if (!image.complete) setLoaded(false);
    image.onload = () => setLoaded(true);
  }, [src]);

  return <StyledImg src={src} alt={alt} loaded={loaded} {...props} />;
};
