import { FC } from "react";
import styled from "styled-components";

import { useAppTranslation } from "@hooks";
// import { CheckRoundIcon, Text, Icon } from "@components/common";
// import { Text, Icon } from "@components/common";
import { Text } from "@components/common";

import { TxActionButton, TxActions } from "./TxActions";

// const StyledIcon = styled(Icon)`
//   width: 8.4rem;
//   fill: inherit;
// `;

const StyledText = styled(Text)`
  color: inherit;
  font-size: 2.4rem;
`;

const TxStatusContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.textWallet};
  border-radius: ${({ theme }) => theme.xlRadius};
  padding: ${({ theme }) => theme.xxxlLayoutPadding};
  margin: ${({ theme }) => theme.xxxlLayoutPadding} 0;
  gap: ${({ theme }) => theme.xlLayoutPadding};
  fill: currentColor;
`;

const StyledTxStatus = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

const StyledTxActionButton = styled(TxActionButton)`
  font-size: 1.6rem;
  background-color: ${({ theme }) => theme.colors.darkGray48};
  color: ${({ theme }) => theme.colors.zinc300};
  box-shadow: 0 0.2rem 0 0 ${({ theme }) => theme.colors.zinc500};

  transition: box-shadow ${({ theme }) => theme.transition.eo250},
    background-color ${({ theme }) => theme.transition.eo250};

  &:hover {
    background-color: ${({ theme }) => theme.colors.darkGray};
    box-shadow: 0 0.1rem 0 0 ${({ theme }) => theme.colors.zinc500};
  }
`;

export interface TxStatusProps {
  transactionCompletedLabel?: string;
  exit: () => void;
}

export const TxStatus: FC<TxStatusProps> = ({ transactionCompletedLabel, exit, children, ...props }) => {
  const { t } = useAppTranslation("common");

  const actionButtonLabel = transactionCompletedLabel ?? t("components.transaction.status.exit");

  return (
    <StyledTxStatus {...props}>
      <TxStatusContent>
        <StyledText>{t("components.transaction.status.transaction-completed")}</StyledText>

        {/* <StyledIcon Component={CheckRoundIcon} /> */}
      </TxStatusContent>

      <TxActions>
        <StyledTxActionButton onClick={exit} success>
          {actionButtonLabel}
        </StyledTxActionButton>
      </TxActions>

      {children}
    </StyledTxStatus>
  );
};
