import { SerializedError } from "@reduxjs/toolkit";
import { EthersError, PriceFetchingError, SdkError, SimulationError, TenderlyError, ZapperError } from "@yfi/sdk";

const ETHERS_ERRORS = {
  [EthersError.FAIL_TOKEN_FETCH]: "ERROR FETCHING TOKEN",
  [EthersError.NO_DECIMALS]: "NO DECIMALS SET FOR VAULT",
  [EthersError.NO_PRICE_PER_SHARE]: "NO PRICE PER SHARE SET FOR VAULT",
  [EthersError.POPULATING_TRANSACTION]: "ERROR POPULATING TRANSACTION. MAKE SURE YOU HAVE ENOUGH FUNDS FOR GAS FEES.",
};
const PRICE_FETCHING_ERRORS = {
  [PriceFetchingError.FETCHING_PRICE_PICKLE]: "ERROR FETCHING PRICE FROM PICKLE",
  [PriceFetchingError.FETCHING_PRICE_ORACLE]: "ERROR FETCHING PRICE FROM ORACLE",
};
const SDK_ERRORS = {
  [SdkError.NO_SLIPPAGE]: "NO SLIPPAGE SET",
};
const SIMULATION_ERRORS = {
  [SimulationError.NO_LOG]: "NO LOG OF TRANSFERING TOKEN",
  [SimulationError.PARTIAL_REVERT]: "PARTIAL REVERT SIMULATING CALL",
  [SimulationError.TENDERLY_RESPONSE_ERROR]: "ERROR ON TENDERLY RESPONSE",
};
const TENDERLY_ERRORS = {
  [TenderlyError.SIMULATION_CALL]: "SIMULATION CALL TO TENDERLY FAILED",
};
const ZAPPER_ERRORS = {
  [ZapperError.ZAP_IN]: "ERROR ZAPPING IN TOKEN",
  [ZapperError.ZAP_IN_APPROVAL_STATE]: "ERROR GETTING APPROVAL ZAP IN STATE OF TOKEN",
  [ZapperError.ZAP_IN_APPROVAL]: "ERROR APPROVING ZAP IN OF TOKEN",
  [ZapperError.ZAP_OUT]: "ERROR ZAPPING OUT TOKEN",
  [ZapperError.ZAP_OUT_APPROVAL_STATE]: "ERROR GETTING APPROVAL ZAP OUT STATE OF TOKEN",
  [ZapperError.ZAP_OUT_APPROVAL]: "ERROR APPROVING ZAP OUT OF TOKEN",
};

export const parseError = (e: any): SerializedError => {
  if (e instanceof EthersError) {
    return {
      message: ETHERS_ERRORS[e.error_code] || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof PriceFetchingError) {
    return {
      message: PRICE_FETCHING_ERRORS[e.error_code] || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof TenderlyError) {
    return {
      message: TENDERLY_ERRORS[e.error_code] || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof ZapperError) {
    return {
      message: ZAPPER_ERRORS[e.error_code] || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof SimulationError) {
    return {
      message: SIMULATION_ERRORS[e.error_code] || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof SdkError) {
    return {
      message: (e.error_code && SDK_ERRORS[e.error_code]) || e.message,
      name: e.error_type,
      code: e.error_code,
    };
  }

  if (e instanceof Error) {
    return {
      message: e.message,
    };
  }

  return {
    message: "AN UNKNOWN ERROR OCCURRED",
    name: "UNKNOWN",
  };
};
