import { FC } from "react";
import styled from "styled-components";

import { useAppDispatch } from "@hooks";
import { AlertsActions } from "@store";
import { Icon, CloseIcon } from "@components/common";
import { AlertTypes } from "@types";
import { device } from "@themes/default";

const StyledAlert = styled.div<{ type: AlertTypes }>`
  overflow: hidden;
  overflow-y: auto;
  padding: ${({ theme }) => theme.buttonPadding};
  position: relative;
  width: 100%;
  max-width: calc(max-content * 1.2);
  border-radius: ${({ theme }) => theme.smRadius};
  transform: translate3d(0, 0, 0);
  z-index: 1;
  pointer-events: all;

  @media ${device.tabletL} {
    padding: ${({ theme }) => theme.smLayoutPadding} ${({ theme }) => theme.lgLayoutPadding};
    font-size: 1.4rem;
  }

  @media ${device.tabletS} {
    max-width: 26rem;
    padding: ${({ theme }) => theme.xxsLayoutPadding} ${({ theme }) => theme.smLayoutPadding};
    font-size: 1rem;
    text-align: center;
  }

  ${({ type, theme }) => {
    let background = theme.alerts.default.background;
    let color = theme.alerts.default.color;

    if (type === "error") {
      background = theme.alerts.error.background;
      color = theme.alerts.error.color;
    } else if (type === "success") {
      background = theme.alerts.success.background;
      color = theme.alerts.success.color;
    } else if (type === "info") {
      background = theme.alerts.info.background;
      color = theme.alerts.info.color;
    } else if (type === "warning") {
      background = theme.alerts.warning.background;
      color = theme.alerts.warning.color;
    }
    return `background: ${background}; color: ${color}; fill: ${color}`;
  }}
`;

const CloseAlert = styled.div`
  display: inline-block;
  margin-left: 1.2rem;
  cursor: pointer;
  opacity: ${({ theme }) => theme.opac64};
  transition: opacity ${({ theme }) => theme.transition.eo250};

  :hover {
    opacity: 1;
  }

  @media ${device.tabletL} {
    display: none;
  }
`;

const StyledIcon = styled(Icon)`
  width: 1.2rem;
  fill: inherit;
`;

export interface AlertProps {
  id: string;
  className?: string;
  onClose?: () => void;
  type: AlertTypes;
}

export const Alert: FC<AlertProps> = ({ className, onClose, children, id, type, ...props }) => {
  const dispatch = useAppDispatch();
  const closeAlert = () => dispatch(AlertsActions.closeAlert({ alertId: id }));

  const closeButton = (
    <CloseAlert onClick={closeAlert}>
      <StyledIcon Component={CloseIcon} />
    </CloseAlert>
  );

  return (
    <StyledAlert className={className} id={`alert-${id}`} type={type} {...props}>
      {children}
      {closeButton}
    </StyledAlert>
  );
};
