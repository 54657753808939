import { FC } from "react";
import styled, { css } from "styled-components";

import { styledSystem, StyledSystemProps } from "../styledSystem";

import { CardHeader } from "./CardHeader";
import { CardContent } from "./CardContent";
import { CardElement } from "./CardElement";
import { CardEmptyList } from "./CardEmptyList";
import { CardRedirection } from "./CardRedirection";

const bigSize = css`
  /* min-height: 16rem; */
`;

const smallSize = css`
  /* min-height: 12rem; */
`;

const microSize = css`
  /* min-height: 8rem; */
`;

const defaultVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const primaryVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const secondaryVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const backgroundVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const surfaceVariant = css`
  background: transparent;
  color: ${({ theme }) => theme.colors.zinc200};
`;

const sizeStyle = ({ cardSize }: CardProps) => {
  switch (cardSize) {
    case "micro":
      return microSize;
    case "small":
      return smallSize;
    case "big":
      return bigSize;
    default:
      return;
  }
};

const variantStyle = ({ variant }: CardProps) => {
  switch (variant) {
    case "primary":
      return primaryVariant;
    case "secondary":
      return secondaryVariant;
    case "background":
      return backgroundVariant;
    case "surface":
      return surfaceVariant;
    default:
      return defaultVariant;
  }
};

type CardVariant = "primary" | "secondary" | "background" | "surface";
export type CardSizeType = "micro" | "small" | "big";

export interface CardProps extends StyledSystemProps {
  onClick?: () => void;
  variant?: CardVariant;
  cardSize?: CardSizeType;
}

const StyledDiv = styled.div<CardProps>`
  border-radius: ${({ theme }) => theme.smRadius};

  ${variantStyle};
  ${sizeStyle};
  ${styledSystem};
`;

export const Card: FC<CardProps> = (props) => <StyledDiv {...props} />;

export { CardHeader, CardContent, CardElement, CardEmptyList, CardRedirection };
