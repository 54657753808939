import styled from "styled-components";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";

import { useAppTranslation } from "@hooks";
import { Button, Text } from "@components/common";
import { device } from "@themes/default";

interface WalletAddressProps {
  address?: string;
  ensName?: string;
  disabled?: boolean;
  onClick: () => void;
}

const StyledButton = styled(Button)`
  overflow: hidden;
  border: none;
  border-radius: ${({ theme }) => theme.smRadius};
  transition: opacity ${({ theme }) => theme.transition.eo250};
  font-size: 1.6rem;
  margin-left: 0.6rem;
  padding: ${({ theme }) => theme.buttonPadding};

  &[data-connected="false"] {
    box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.bgWallet};
    background: ${({ theme }) => theme.colors.bgWallet};
    color: ${({ theme }) => theme.colors.textWallet};

    &:hover {
      opacity: ${({ theme }) => theme.opac64};
    }

    .paper {
      display: none !important;
    }
  }

  &[data-connected="true"] {
    box-shadow: 0 0 0 ${({ theme }) => theme.mdBoxSize} ${(props) => props.theme.colors.darkGray};
    background: ${({ theme }) => theme.colors.lightGray};
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      opacity: ${({ theme }) => theme.opac64};
    }
    .paper {
      display: inline-block !important;
      /* margin-right: 1.2rem !important; */
      margin-left: 1.2rem !important;

      @media ${device.mobileXS} {
        display: none !important;
      }
    }
  }

  @media ${device.mobileL} {
    width: auto;
  }

  @media ${device.mobileS} {
    font-size: 1.4rem;
  }
`;

const StyledText = styled(Text)`
  text-transform: uppercase;
`;

export const ConnectWalletButton = ({ address, ensName, disabled, onClick }: WalletAddressProps) => {
  const { t } = useAppTranslation("common");
  let buttonMessage;

  if (!address) {
    buttonMessage = t("components.connect-button.connect");
  } else {
    buttonMessage = ensName ?? address.substring(0, 6) + "..." + address.substring(address.length - 4, address.length);
  }

  return (
    <StyledButton
      onClick={() => onClick && onClick()}
      disabled={disabled}
      data-testid="connect-wallet-button"
      data-connected={!!address}
    >
      <StyledText ellipsis>{buttonMessage}</StyledText>

      {address && (
        <>
          {/* <Jazzicon diameter={24} seed={jsNumberForAddress(address.toString())} /> */}
          <Jazzicon seed={jsNumberForAddress(address.toString())} />
        </>
      )}
    </StyledButton>
  );
};
