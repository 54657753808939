import { FC } from "react";
import styled from "styled-components";

import { Button, SpinnerLoading } from "@components/common";

export const TxSpinnerLoading = styled(SpinnerLoading)`
  font-size: 0.8rem;
`;

export const StyledTxActionButton = styled(Button)<{ contrast?: boolean; success?: boolean; disabled?: boolean }>`
  flex: 1;
  gap: 0.4rem;
  height: 4rem;
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.colors.bgSky12};
  color: ${({ theme }) => theme.colors.sky};
  box-shadow: 0 0.2rem 0 0 ${({ theme }) => theme.colors.sky};

  transition: opacity ${({ theme }) => theme.transition.eo250}, box-shadow ${({ theme }) => theme.transition.eo250},
    background-color ${({ theme }) => theme.transition.eo250};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bgSky04};
    box-shadow: 0 0.1rem 0 0 ${({ theme }) => theme.colors.sky};
  }

  ${(props) =>
    props.disabled &&
    `
    background: ${props.theme.colors.darkGray};
    color: ${props.theme.colors.zinc200};
    opacity: ${props.theme.opac64};
    box-shadow: 0 0.2rem 0 0 transparent};
  `}

  ${(props) =>
    props.contrast &&
    !props.disabled &&
    `
    background: ${props.theme.colors.bgSky12};
  `}

  ${(props) =>
    props.success &&
    !props.disabled &&
    `
    // background: ${props.theme.colors.bgGreen};
  `}
`;

const StyledTxActions = styled.div`
  display: flex;
  align-items: flex-end;
  margin: calc(-${({ theme }) => theme.txModal.gap} / 4) calc(-${({ theme }) => theme.txModal.gap} / 2);
  margin-top: auto;

  ${StyledTxActionButton} {
    margin: calc(${({ theme }) => theme.txModal.gap} / 2);
  }
`;

export interface TxActionsProps {}

export const TxActions: FC<TxActionsProps> = ({ children, ...props }) => (
  <StyledTxActions {...props}>{children}</StyledTxActions>
);

export interface TxActionButtonProps {
  isLoading?: boolean;
  contrast?: boolean;
  success?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const TxActionButton: FC<TxActionButtonProps> = ({
  isLoading,
  contrast,
  success,
  disabled,
  children,
  ...props
}) => (
  <StyledTxActionButton contrast={contrast} success={success} disabled={disabled} {...props}>
    {isLoading ? <TxSpinnerLoading /> : children}
  </StyledTxActionButton>
);
