import { Suspense } from "react";
import { Provider } from "react-redux";
import { createGlobalStyle } from "styled-components";

import { Container } from "@container";
import { device } from "@themes/default";
import { getStore } from "@frameworks/redux";
import { AppContextProvider, NavSideMenuContextProvider } from "@context";
import { Routes } from "@routes";
import { Themable } from "@containers";

import "@i18n";
import "@assets/fonts/Font.css";
import "@core/frameworks/blocknative/Onboard.css";
import "@core/frameworks/blocknative/Notify.css";

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  ::-webkit-scrollbar-track {
    border: none;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  html {
    font-size: 62.5%;
    background: rgb(42, 48, 60);
  }

  * {
    box-sizing: border-box;
    font-family: inherit;
    font-weight: 500;
    font-variant: none;
    font-smooth: always;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *:focus {
    outline: none;
  }

  body {
    overflow: hidden;
    overflow-y: scroll;
    font-size: 1.6rem;
    font-family: ${(props) => props.theme.globalFont};
    color: ${(props) => props.theme.colors.zinc200};
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    transition-duration: .15s
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  #background-radial-gradient {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    transform: unset;
    background: radial-gradient(
      150% 100% at 50% 0%,
      rgba(252, 11, 84, 0.04) 0%,
      rgba(18, 18, 24, 0.48) 50%,
      rgba(3, 3, 9, 0.48) 100%
    );
    pointer-events: none;
    z-index: -1;
  }

  h3 {
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 1.6rem;

    @media ${device.tablet} {
      margin-top: 1.4rem;
    }
    @media ${device.mobileL} {
      margin-top: 1.2rem;
    }
  }

  [disabled],
  .disabled {
    cursor: default;
    pointer-events: none;
  }

  .bn-onboard-modal {
    z-index: ${(props) => props.theme.zindex.onboardModal}
  }
`;

const container = new Container();
const store = getStore(container);

export const App = () => {
  return (
    <Provider store={store}>
      <AppContextProvider context={container.context}>
        <NavSideMenuContextProvider>
          <Themable>
            <GlobalStyle />
            <Suspense fallback={null}>
              <Routes />
            </Suspense>
          </Themable>
        </NavSideMenuContextProvider>
      </AppContextProvider>
    </Provider>
  );
};

export default App;
